import React from 'react';
import {URLAPI} from '../../../config';

const VisitantModal = ({
                           item = {
                               ruc: '',
                               nombres: '',
                               apellidos: '',
                               foto: ''
                           }
                       }) => {

    return (
        <div className="modal fade text-left" id="visitantModal" tabindex="-1" role="dialog"
             aria-labelledby="myModalLabel33" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="text-bold-400">{item.nombres + " " + item.apellidos}</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <i className="bx bx-x"/>
                        </button>
                    </div>
                    <form action="#">
                        <div className="modal-body container">
                            <div className="row">
                                <div className="col-md-6">
                                    {
                                        item.foto != null ?
                                            <img
                                                src={URLAPI + item.foto}
                                                width="100%"
                                                height="70%"
                                                alt="avatar"

                                            /> : <img
                                                src={window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                                                width="100%"
                                                height="100%"
                                                alt="avatar"
                                            />

                                    }

                                </div>
                                <div className="col-md-6 mt-1">
                                    <p>{item.ruc}</p>
                                    <p>{item.cargo}</p>
                                    {(item.empresa) &&
                                        <p>Empresa: {item.empresa}</p>
                                    }
                                    {(item.puesto) &&
                                        <p>Cargo: {item.puesto}</p>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default VisitantModal;
