import React, { useEffect, useState } from "react";
import { get_status } from "../../../config";
import Card from "../../../components/card";
import {
  updateVisit,
  getVisitsDetails,
  visitResponseExit,
  clearVisit,
  UPDATE_VISIT
} from "../../../redux/visits";
import {getAllVisitsAndDepartures, } from "../../../redux/monitoringDeparturesAndVisits"
import { useDispatch, useSelector } from "react-redux";
import CardItem from "../../../components/card-item";
import Button from "../../../components/button";
import { URLAPI } from "../../../config";
import DeparteeModal from "./visit-detail-modal";
import LoadingSpinner from "../../../components/loading-spinner";
import {toast, ToastContainer} from 'react-toastify';
import Modal from "../../../components/modal";
import SelectInput from "../../../components/select";

const VisitsDetailsModal = (props) => {
  const { visit_id, open, setOpen } = props;
  const dispatch = useDispatch();
  const { token } =
    useSelector((state) => state.users_module.login.data) || null;

  const UserID = {
    can_approve_visit: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "can_approve_visit"
      )
    ),
    view_visits: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "view_visits"
      )
    ),
    response_exit: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "response_exit"
      )
    ),
  };

  const _get_visits_details = useSelector(
    (state) => state.visits_module._get_visits_details
  );

  const _get_all_visits = useSelector(
    (state) => state.visits_module._get_all_visits
  );

  const _update_visit = useSelector(
    (state) => state.visits_module._update_visit
  );

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [DataSelected, setDataSelected] = useState({
    visit_id: 0,
    establishment_id: "",
    establishment_name: "",
    reason_description: "",
    reason_decline_description: "",
    observation: "",
    status_description: "",
    status_id: "",
    date_scheduled: "",
    date_request_exit: null,
    date_request_return: null,
    employees_info: [],
    visitants_info: [],
    suppliers_info: [],
  });
  
  const [modalSelected, setModalSelected] = useState({
    RUC: "test",
    empresa: "empresatest",
    nombres: "nombretest",
    apellidos: "apellidostest",
    photo: "",
    cargo: "cargotest",
  });
  
  useEffect(() => {
    dispatch(getVisitsDetails(token, visit_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (
      _get_visits_details &&
      _get_visits_details.data &&
      _get_visits_details.data.datos !== undefined
    ) {
      setDataSelected(_get_visits_details.data.datos[0]);
    }
  }, [_get_visits_details, visit_id, open]);

  useEffect(() => {
    console.log(
      "get_all_visits",
      _get_all_visits.data.datos.find((obj) => obj.id === DataSelected.visit_id)
        ?.proveedores[0]
    );
  // eslint-disable-next-line
  }, [_get_all_visits]);

  const _get_all_reason_decline = useSelector((store) => store.reason_decline_module._get_all_reason_decline);

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reasonsDeclide, setReasonsDecline] = useState([]);
  const [idReasonDeclide, setIdReasonDecline] = useState(0);

  useEffect(() => {
    if (_get_all_reason_decline && _get_all_reason_decline.data && _get_all_reason_decline.data.datos){
      let datas = _get_all_reason_decline.data.datos.filter(x => x.activo === true);
      setReasonsDecline(datas);
    }
    
  }, [_get_all_reason_decline])

  useEffect(() => {
    if (_update_visit.status === 200) {
        dispatch(clearVisit(UPDATE_VISIT));
        //toast.success(_request_opening.message);
        dispatch(getAllVisitsAndDepartures(token, 1));
        setModal(false);
        setIdReasonDecline(0);
        setIsLoading(false);
    } else if (_update_visit.status !== 0) {
        toast.error(_update_visit.message);
        dispatch(clearVisit(UPDATE_VISIT));
        setIsLoading(false);
    }
}, [_update_visit, dispatch, token]);

  const handleReject = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (idReasonDeclide === 0) {
      toast.error("Debe seleccionar un motivo", {position: toast.POSITION.TOP_RIGHT});
      setIsLoading(false);
      return;
    }
    setDataSelected({ ...DataSelected, status_description: "ANULADO" });
    let data = { estado: "ANULADO", id_reason_decline: idReasonDeclide };
    dispatch(updateVisit(token, DataSelected.visit_id, data));
  };

  const handleResponseExit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDataSelected({ ...DataSelected, status_description: "SALIDA ACEPTADA" });
    dispatch(visitResponseExit(token, DataSelected.visit_id));
  };

  const handleApprove = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDataSelected({ ...DataSelected, status_description: "APROBADO" });
    let data = { estado: "APROBADO" };
    dispatch(updateVisit(token, DataSelected.visit_id, data));
  };

  useEffect(() => {
    console.log(
      "Dataselected:",
      DataSelected,
      DataSelected.employees_info.length
    );
  }, [DataSelected]);

  return (
    <div className='content-wrapper' style={{ background: "#F2F4F3", overflowY: 'auto' }}>
      <ToastContainer/>
      {isLoading && <LoadingSpinner/>}
      <div className='content-body'>
        <div className='app-content-overlay' />
        <div className='email-app-area'>
          <div className='email-app-list-wrapper'>
            <div className='email-app-list'></div>
          </div>
          <div
            className='navbar navbar-light bg-white p-2'
            style={{
              borderBottom: "1px solid #DFE3E",
            }}
          >
            <div className='email-header-left d-flex align-items-center mb-1'>
              <div to='/' className='go-back mr-50' onClick={setOpen}>
                <span className='fonticon-wrap d-inline'>
                  <i className='bx bx-arrow-back' />
                </span>
              </div>
              <h5 className='email-detail-title font-weight-normal mb-0'>
                {DataSelected.title}
              </h5>
            </div>
            <div className='email-header-right mb-1 ml-2 pl-1'>
              <span
                className={`badge badge-light-${get_status(
                  DataSelected.status_description
                )} badge-pill ml-1`}
              >
                {DataSelected.status_description}
              </span>
            </div>
          </div>
          <div className='email-scroll-area'>
            <div className='row'>
              <div className='col-12 p-10'>
                <div className='collapsible email-detail-head'>
                  <Card
                    //establishment_name
                    title={DataSelected.establishment_name}
                    description={
                      <>
                        <h6>{DataSelected.title}</h6>
                        <span>Fecha de visita: </span>
                        <strong>
                          {new Date(
                            DataSelected.date_scheduled + " 00:00:00"
                          ).toLocaleDateString("es-ES", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </strong>
                        <br />
                        {"Creado por: "}
                        <strong>{DataSelected.user_create_name}</strong>{" "}
                      </>
                    }
                  >
                    <form className='row'>
                      {DataSelected.date_approval && (
                        <div className='col-12 mb-0'>
                          <h6>Fecha de solicitud de aprobación:</h6>
                          {DataSelected.date_approval}
                        </div>
                      )}
                      {DataSelected.fecha_respuesta_aprobacion && (
                        <div className='col-12 mb-0'>
                          <h6>Fecha de aprobación:</h6>
                          {DataSelected.fecha_respuesta_aprobacion}
                        </div>
                      )}
                      <div className='col-md-12 col-12 mb-1'>
                        <h6>Observación:</h6>
                        {DataSelected.observation} <br />
                      </div>
                      {DataSelected.reason_decline_description && (
                        <div className='col-12 mb-1'>
                          <strong style={{color:"#475F7B"}}>Motivo de rechazo: </strong>
                          {DataSelected.reason_decline_description}
                        </div>                
                      )}
                      {DataSelected.employees_info.length !== 0 ? 
                      DataSelected.employees_info.map((supplier, index) => (
                        <div className='col col-sm-12 col-md-6'>
                          <CardItem
                            data-toggle='modal'
                            data-target='#visitantModal'
                            onClick={() => {
                              setModalSelected({
                                RUC: _get_all_visits.data.datos.find(
                                  (obj) => obj.id === DataSelected.visit_id
                                )?.empleados[index]?.ruc,
                                empresa: _get_all_visits.data.datos.find(
                                  (obj) => obj.id === DataSelected.visit_id
                                )?.empleados[index]?.empresa,
                                cargo: _get_all_visits.data.datos.find(
                                  (obj) => obj.id === DataSelected.visit_id
                                )?.empleados[index]?.puesto,
                                nombres: _get_all_visits.data.datos.find(
                                  (obj) => obj.id === DataSelected.visit_id
                                )?.empleados[index]?.nombres,
                                apellidos: _get_all_visits.data.datos.find(
                                  (obj) => obj.id === DataSelected.visit_id
                                )?.empleados[index]?.apellidos,
                                photo:
                                  URLAPI +
                                  DataSelected.employees_info[index].employee_photo,
                              });
                              setIsModalVisible(true);
                            }}
                          >
                            <>
                              <div className=' d-flex'>
                                <div className='list-icon mr-1'>
                                  <div className='avatar bg-rgba-primary m-0'>
                                    <img
                                      className='img-fluid'
                                      src={
                                        URLAPI +
                                        DataSelected.employees_info[index]
                                          .employee_photo
                                      }
                                      alt='img placeholder'
                                      height='38'
                                      width='38'
                                    />
                                  </div>
                                </div>
                                <div className='list-content'>
                                  <span className='list-title text-bold-500'>
                                    {
                                      DataSelected.employees_info[index]
                                        .employee_full_name
                                    }
                                  </span>
                                  <small className='text-muted d-block'>
                                    Cargo:{" "}
                                    {
                                      _get_all_visits.data.datos.find(
                                        (obj) =>
                                          obj.id === DataSelected.visit_id
                                      )?.empleados[index]?.puesto
                                    }
                                  </small>
                                  <small className='text-muted d-block'></small>
                                </div>
                              </div>
                            </>
                          </CardItem>
                        </div>
                      )) : null}
                      {DataSelected.visitants_info.length !== 0
                        ? DataSelected.visitants_info.map((supplier, index) => (
                            <div className='col col-sm-12 col-md-6'>
                              <CardItem
                                data-toggle='modal'
                                data-target='#visitantModal'
                                onClick={() => {
                                  setModalSelected({
                                    RUC: _get_all_visits.data.datos.find(
                                      (obj) => obj.id === DataSelected.visit_id
                                    )?.visitantes[index]?.ruc,
                                    empresa: "No aplica",
                                    cargo: "No aplica",
                                    nombres: _get_all_visits.data.datos.find(
                                      (obj) => obj.id === DataSelected.visit_id
                                    )?.visitantes[index]?.nombres,
                                    apellidos: _get_all_visits.data.datos.find(
                                      (obj) => obj.id === DataSelected.visit_id
                                    )?.visitantes[index]?.apellidos,
                                    photo:
                                      URLAPI +
                                      DataSelected.visitants_info[index]
                                        .visitant_photo,
                                  });
                                  setIsModalVisible(true);
                                }}
                              >
                                <>
                                  <div className=' d-flex'>
                                    <div className='list-icon mr-1'>
                                      <div className='avatar bg-rgba-primary m-0'>
                                        <img
                                          className='img-fluid'
                                          src={
                                            URLAPI +
                                            DataSelected.visitants_info[index]
                                              .visitant_photo
                                          }
                                          alt='img placeholder'
                                          height='38'
                                          width='38'
                                        />
                                      </div>
                                    </div>
                                    <div className='list-content'>
                                      <span className='list-title text-bold-500'>
                                        {
                                          DataSelected.visitants_info[index]
                                            .visitant_full_name
                                        }
                                      </span>
                                      <small className='text-muted d-block'></small>
                                    </div>
                                  </div>
                                </>
                              </CardItem>
                            </div>
                          ))
                        : null}
                      {DataSelected.suppliers_info.length !== 0
                        ? DataSelected.suppliers_info.map((supplier, index) => (
                            <div className='col col-sm-12 col-md-6'>
                              <CardItem
                                data-toggle='modal'
                                data-target='#visitantModal'
                                onClick={() => {
                                  setModalSelected({
                                    RUC: _get_all_visits.data.datos.find(
                                      (obj) => obj.id === DataSelected.visit_id
                                    )?.proveedores[index]?.ruc,
                                    empresa: "No aplica",
                                    cargo: "No aplica",
                                    nombres: _get_all_visits.data.datos.find(
                                      (obj) => obj.id === DataSelected.visit_id
                                    )?.proveedores[index]?.nombres,
                                    apellidos: _get_all_visits.data.datos.find(
                                      (obj) => obj.id === DataSelected.visit_id
                                    )?.proveedores[index]?.apellidos,
                                    photo:
                                      URLAPI +
                                      DataSelected.suppliers_info[index]
                                        ?.visitant_photo,
                                  });
                                  setIsModalVisible(true);
                                }}
                              >
                                <>
                                  <div className=' d-flex'>
                                    <div className='list-icon mr-1'>
                                      <div className='avatar bg-rgba-primary m-0'>
                                        <img
                                          className='img-fluid'
                                          src={
                                            URLAPI +
                                            DataSelected.suppliers_info[index]
                                              ?.visitant_photo
                                          }
                                          alt='img placeholder'
                                          height='38'
                                          width='38'
                                        />
                                      </div>
                                    </div>
                                    <div className='list-content'>
                                      <span className='list-title text-bold-500'>
                                        {
                                          DataSelected.suppliers_info[index]
                                            .visitant_full_name
                                        }
                                      </span>
                                      <small className='text-muted d-block'></small>
                                    </div>
                                  </div>
                                </>
                              </CardItem>
                            </div>
                          ))
                        : null}{" "}
                    </form>
                    <form className='row'>
                      {DataSelected.date_create && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de creación:{" "}
                            <strong> {DataSelected.date_create}</strong>{" "}
                          </h6>
                        </div>
                      )}

                      {DataSelected.date_request_aprove && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de solicitud de aprobación:{" "}
                            <strong> {DataSelected.date_request_aprove}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_response_aprove && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de Respuesta de respuesta de aprobación:{" "}
                            <strong>
                              {" "}
                              {DataSelected.date_response_aprove}
                            </strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_request_return && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Solicitud de apertura de puerta retorno:{" "}
                            <strong> {DataSelected.date_request_return}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_response_return && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Solicitud de apertura de puerta retorno:{" "}
                            <strong>
                              {" "}
                              {DataSelected.date_response_return}
                            </strong>{" "}
                          </h6>
                        </div>
                      )}
                    </form>
                    {(DataSelected.status_description === "EN ESPERA" ||
                      DataSelected.status_description ===
                        "RETORNO REQUERIDO" ||
                        DataSelected.status_description ===
                          "PENDIENTE VISITA ZONAL") &&
                    UserID.view_visits.codigo === "view_visits" &&
                    UserID.can_approve_visit.codigo === "can_approve_visit" ? (
                      <div className='col-12 d-flex justify-content-end'>
                        <Button
                          text='Denegar'
                          onClick={() => {setModal(true); setIdReasonDecline(0);}}
                          theme='danger'
                          type='button'
                        />
                        <p style={{ opacity: 0 }}>__</p>
                        <Button
                          text='Aprobar'
                          onClick={handleApprove}
                          theme='success'
                          type='button'
                        />
                      </div>
                    ) : DataSelected.status_description ===
                        "SALIDA REQUERIDA" &&
                      UserID.view_visits.codigo === "view_visits" &&
                      UserID.can_approve_visit.codigo ===
                        "can_approve_visit" ? (
                      <div className='col-12 d-flex justify-content-end'>
                        <Button
                          text='Aprobar apertura'
                          onClick={handleResponseExit}
                          theme='success'
                          type='button'
                        />
                        <p style={{ opacity: 0 }}>__</p>
                      </div>
                    ) : null}
                  </Card>
                </div>
                {isModalVisible && (
                  <DeparteeModal
                    show={isModalVisible}
                    handleClose={() => setIsModalVisible(false)}
                    empresa={modalSelected.empresa}
                    nombres={modalSelected.nombres}
                    apellidos={modalSelected.apellidos}
                    cargo={modalSelected.cargo}
                    RUC={modalSelected.RUC}
                    photo={modalSelected.photo}
                    // other modal props...
                  >
                    {/* Modal content goes here */}
                  </DeparteeModal>
                )}
              </div>
            </div>
            {/* email details  end*/}
            <div className='row px-2 mb-4'>
              <div className='col-12 px-0' />
            </div>
            <Modal className="modal-main" show={modal} style={{width: "30%"}}>
              <form onSubmit={handleReject}>
                  {isLoading && <LoadingSpinner/>}
                  <div className="card-body">
                      <h5 className="titulo_modal type_text mb-2">Detalles</h5>
                      <div className="form-group">
                        <label>Motivo</label>
                        <SelectInput
                          id='idReasonDecline'
                          name='idReasonDecline'
                          options={reasonsDeclide.map((v) => ({
                            name: v.descripcion,
                            id: v.id,
                        }))}
                          onChange={(e) => setIdReasonDecline(parseInt(e.target.value))}
                        />
                      </div>
                  </div>
                  <div className="card-footer text-center">
                      <button className={`btn btn-success mb-1 button_div`}>Guardar</button>
                      <button type="button" onClick={() => {setModal(false);}} className={`btn btn-outline-danger button_div`}>Cancelar</button>
                  </div>
              </form>
          </Modal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisitsDetailsModal;
