import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import Select from "../../../components/select";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_establishments,
    CREATE_ESTABLISHMENTS_SUCCESS,
    get_all_establishments,
    clear_establishment
} from '../../../redux/establishment';
import LoadingSpinner from "../../../components/loading-spinner";

/** @module  Pages/Establishment/CreateEstablishment */


const CreateEstablishment = () => {
    let history = useHistory();
    const _companies = useSelector(
        (state) => state.company_module._get_all_companies
    );

    const _zones = useSelector(
        (state) => state.zone_module._get_all_zones
    );

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _create_establishments = useSelector(
        (state) => state.establishment_module._create_establishments
    );

    const dispatch = useDispatch();
    const [form, setForm] = useState({
        codigo: "",
        nombre: "",
        id_empresa: 0,
        id_zona: 0,
    });
    const [companies, setCompanies] = useState([]);
    const [zones, setZones] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_companies.data.datos) {
            setCompanies(_companies.data.datos);
        }
    }, [_companies]);

    useEffect(() => {
        if (_zones.data.datos) {
            let value_zones = _zones.data.datos.filter(x => x.activo === true)
            setZones(value_zones);
        }
    }, [_zones]);

    useEffect(() => {
        if (_create_establishments) {
            if (_create_establishments.status === 201) {
                toast.success("El establecimiento");
                dispatch(clear_establishment(CREATE_ESTABLISHMENTS_SUCCESS));
                dispatch(get_all_establishments(token));
                setIsLoading(false);
                history.push("/establecimientos");
            } else if (_create_establishments.status !== 0) {
                if (_create_establishments.message) {
                    toast.error(_create_establishments.message)
                    dispatch(clear_establishment(CREATE_ESTABLISHMENTS_SUCCESS));
                    setIsLoading(false);
                }
            }
        }
    }, [_create_establishments, dispatch, history, token]);

    const handleChange = (e) => {
        if (e.target.name === "id_empresa" || e.target.name === "id_zona") {
            //parse to int
            setForm({
                ...form,
                [e.target.name]: parseInt(e.target.value),
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }

    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate data
        if (form.codigo === "") {
            toast.error("Debe ingresar el código");
            setIsLoading(false);
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        if (form.id_empresa === 0) {
            toast.error("Debe seleccionar la empresa");
            setIsLoading(false);
            return;
        }
        if (form.id_zona === 0) {
            toast.error("Debe seleccionar la zona");
            setIsLoading(false);
            return;
        }
        dispatch(create_establishments(token, form));
    }

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Establecimientos"
                            items={[
                                {label: "Establecimientos", link: "/establecimientos"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear establecimiento</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <Input
                                                label="Codigo"
                                                name="codigo"
                                                onChange={handleChange}
                                                value={form.codigo}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <Input
                                                label="Nombre"
                                                name="nombre"
                                                onChange={handleChange}
                                                value={form.nombre}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Empresa</label>

                                                <Select
                                                    label="Empresa"
                                                    name="id_empresa"
                                                    options={companies.map((company) => ({
                                                        name: company.nombre,
                                                        id: company.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Zona</label>

                                                <Select
                                                    label="Zona"
                                                    name="id_zona"
                                                    options={zones.map((v) => ({
                                                        name: v.nombre,
                                                        id: v.id,
                                                    }))}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/establecimientos" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text={
                                            <>
                                                <i className="bx bx-save"/> Guardar
                                            </>
                                        }
                                        theme="success"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateEstablishment;
