import React from "react";

const ItemList2 = ({
  title,
  subtitle,
  rightDescription,
  SecondrightDescription,
  bulletType,
  item,
  children,
  onClick,
}) => {
  return (
    <li
      className={`media mail-read bg-rgba-${bulletType} `}
      onClick={(e) => onClick(item)}
    >
      <div className='media-body '>
        <div className='user-details'>
          <div className='mail-items'>
            <span className='list-group-item-text text-truncate'>{title}</span>
          </div>
          <div className='mail-meta-item'>
            <span className='float-right'>
              <span className='mail-date '>{rightDescription}</span>
              <span className={`bullet bullet-${bulletType} bullet-sm`}></span>
            </span>
          </div>
        </div>
        <div className='mail-message '>
          <p className='list-group-item-text  truncate mb-0'>{subtitle}</p>
          <div className='mail-meta-item'>
            <span className='float-right'>
              <span className='mail-date '>{SecondrightDescription}</span>
            </span>
          </div>
        </div>
        {children}
      </div>
    </li>
  );
};

export default ItemList2;
