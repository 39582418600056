import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
// import Modal from "../../../components/modal";
import {ToastContainer} from 'react-toastify';
// import {clear_email_visits, deleteEmailVisit, getAllEmailVisits, DELETE_EMAIL_VISIT} from "../../../redux/email_visits";


const EmailZones = (props) => {
    let history = useHistory();
    // let dispatch = useDispatch();
    const _zones = useSelector(
        (state) => state.zone_module._get_all_zones
    );
    // const {token} = useSelector((state) => state.users_module.login.data) || null;

    const [data, setData] = useState([]);

    useEffect(() => {
        if (_zones.data) {
            let values = _zones.data.datos.filter(x => x.activo===true);
            setData(values);
        }
    }, [_zones]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    // const [modal, setModal] = useState(false);
    // const [id, setId] = useState(null);

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);

        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_zones').length > 0) {
                act.push({
                    name: "email",
                    label: "Correos",
                    icon: "bx bx-show",
                    color: "primary",
                    onClick: (item) => {
                        history.push(`/zona-correo/editar/${item.id}`);
                    },
                })
            }
            // if (permisos.filter(x => x.codigo === 'delete_email').length > 0) {
            //     act.push({
            //         name: "delete",
            //         label: "Eliminar",
            //         icon: "bx bxs-trash",
            //         color: "danger",
            //         onClick: (item) => {
            //             setModal(true);
            //             setId(item.id)
            //         },
            //     })
            // }
        }
        setActions(act);
    }, [permisos, history]);

    // const _delete_email = useSelector(
    //     (state) => state.email_visit_module._delete_email_visit
    // );
    //
    // useEffect(() => {
    //     if (_delete_email.status === 200) {
    //         toast.success(_delete_email.data);
    //         dispatch(clear_email_visits(DELETE_EMAIL_VISIT));
    //         history.push("/correos");
    //         dispatch(getAllEmailVisits(token));
    //         setModal(false);
    //         setId(null);
    //     } else if (_delete_email.status !== 0) {
    //         dispatch(clear_email_visits(DELETE_EMAIL_VISIT));
    //         toast.error(_delete_email.message);
    //     }
    // }, [_delete_email, history, token, dispatch]);
    //
    // const handleDelete = () => {
    //     dispatch(deleteEmailVisit(token, id));
    // };


    return (
        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Zonas - Correos"
                            items={[{label: "Lista", link: "/zonas-correo"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                <div className="table-responsive">
                                    {data ?
                                        <DataTable
                                            dataTable={data}
                                            columns={[
                                                {
                                                    name: "nombre",
                                                    label: "Nombre",
                                                    type: "text",
                                                },
                                                {
                                                    name: "usuario_jefe_zonal",
                                                    label: "Usurio jefe zonal",
                                                    type: "object",
                                                    field_show: "nombres"
                                                },
                                                {
                                                    name: "fecha_creacion",
                                                    label: "Fecha Creacion",
                                                    type: "text",
                                                },
                                            ]}
                                            actions={actions}
                                        />
                                    :
                                        <div className="mt-2 text-center">
                                            <p>No existe datos a mostrar</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<Modal className="modal-main" show={modal} style={{width: "40%"}}>*/}
            {/*    <div className="modal-content">*/}
            {/*        <div className="modal-body">*/}
            {/*            <div className="text-center">*/}
            {/*                <h5>¿Está seguro de eliminar el registro?</h5>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="modal-footer">*/}
            {/*            <button type="button" className="btn btn-primary" onClick={() => {handleDelete()}}>*/}
            {/*                SI*/}
            {/*            </button>*/}
            {/*            <button type="button" className="btn btn-secondary" onClick={() => {setModal(false)}}>*/}
            {/*                NO*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</Modal>*/}
        </div>
    );
};

export default EmailZones;
