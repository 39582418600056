import {LOGOUT} from './users'
import axios from "axios";
import {
    URLAPI,
    VISITS_DEPARTURES_MONITORING_PATH   ,
} from "../config";

export const GET_ALL_VISITS_AND_DEPARTURES = "GET_ALL_VISITS_AND_DEPARTURES";
const init = {
    _get_all_visits_and_departures: {
        data: [],
        status: 0,
        message: "",
    },}

    export const visits_and_departures_module = (state = init, action) => {
        switch (action.type) {
            case GET_ALL_VISITS_AND_DEPARTURES:
                return {
                    ...state,
                    _get_all_visits_and_departures: action.payload,
                };
            case LOGOUT:
                return init;
            default:
                return state;
        }
    }
    export const getAllVisitsAndDepartures = (token, page) => async (dispatch) => {
        try {
            const response = await axios.get(`${URLAPI}${VISITS_DEPARTURES_MONITORING_PATH}?page=${page}`, {
                headers: {
                    Authorization: `Token ${token}`,
                },
            });
            dispatch({
                type: GET_ALL_VISITS_AND_DEPARTURES,
                payload: {
                    data: response.data,
                    status: response.status,
                    message: ""
                }
            });
        } catch (error) {
            if (error.response != null) {
                dispatch({
                    type: GET_ALL_VISITS_AND_DEPARTURES,
                    payload: {
                        data: [],
                        status: error.response.status,
                        message: error.response.data.mensaje
                    }
                });
            } else {
                dispatch({
                    type: GET_ALL_VISITS_AND_DEPARTURES,
                    payload: {
                        data: [],
                        status: 500,
                        message: "Error en el servidor"
                    }
                });
            }
        }
    }