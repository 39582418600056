import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {
    create_companies,
    clear_status_companie,
    CREATE_COMPANIES_SUCCESS,
    get_all_companies
} from "../../../redux/company";
import LoadingSpinner from "../../../components/loading-spinner";

/** @module  Pages/Company/CreateCompany */

/**
 * Te devuelve un formulario que te permite crear una empresa
 * @returns {JSX.Element}
 *
 * @example
 * <CreateCompany />
 */

const CreateCompany = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    /**
     * @constant {array} companies - Matriz de empresas
     *  Destrucción del estado de la tienda redux. */
    const {_create_companies} = useSelector((state) => state.company_module);

    /**
     * @constant {string} token - Token de autenticación
     *  Desestructuración del token del estado. */
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const [form, setForm] = useState({
        ruc: "",
        nombre: "",
    });

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_create_companies.status === 201) {
            toast.success("La empresa ha sido creada correctamente");
            dispatch(clear_status_companie(CREATE_COMPANIES_SUCCESS));
            setIsLoading(false);
            dispatch(get_all_companies(token));
            history.push("/empresas");
        } else if (_create_companies.status !== 0) {
            if (_create_companies.message) {
                toast.error(_create_companies.message);
                dispatch(clear_status_companie(CREATE_COMPANIES_SUCCESS));
                setIsLoading(false);
            }
        }
    }, [_create_companies, history, dispatch, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate fields
        if (form.ruc === "") {
            toast.error("Debe ingresar el ruc");
            setIsLoading(false);
            return;
        }
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre");
            setIsLoading(false);
            return;
        }
        dispatch(create_companies(token, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Empresas"
                            items={[
                                {label: "Empresas", link: "/empresas"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Crear Empresa</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                id="ruc"
                                                name="ruc"
                                                label="RUC"
                                                value={form.ruc}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Input
                                                id="nombre"
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/empresas" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>

                                    <Button
                                        type="submit"
                                        className="btn btn-success"
                                        onClick={() => {
                                        }}
                                        text="Crear"
                                        theme="success"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateCompany;
