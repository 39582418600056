import React, { useEffect, useState } from "react";
import { get_status } from "../../../config";
import Card from "../../../components/card";
import { useDispatch, useSelector } from "react-redux";
import { updateIncome, clearIncome, UPDATE_INCOME, getIncomeDetails } from '../../../redux/income';
import {getAllVisitsAndDepartures, } from "../../../redux/monitoringDeparturesAndVisits"
import LoadingSpinner from "../../../components/loading-spinner";
import {toast, ToastContainer} from 'react-toastify';
import Button from "../../../components/button";

const IncomeDetail = (props) => {
  const { id, open, setOpen } = props;
  const dispatch = useDispatch();
  const { token } =
    useSelector((state) => state.users_module.login.data) || null;

  const _get_income_details = useSelector(
    (state) => state.income_module._get_income_details
  );
  
  const UserID = {
    approve_income: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "approve_income"
      )
    ),
    response_income: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "response_income"
      )
    ),
  };

  const [DataSelected, setDataSelected] = useState({
    id: 0,
    fecha: "",
    fecha_apertura: "",
    fecha_aprobacion: "",
    estado: "",
    establecimiento: "",
    fecha_solicitud_apertura: null,
    fecha_respuesta_apertura: null,
  });
  
  
  useEffect(() => {
    dispatch(getIncomeDetails(token, id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (
      _get_income_details &&
      _get_income_details.data &&
      _get_income_details.data.datos !== undefined
    ) {
      setDataSelected(_get_income_details.data.datos[0]);
    }
  }, [_get_income_details, id, open]);

  const [isLoading, setIsLoading] = useState(false);

  const _update_income = useSelector((state) => state.income_module._update_income);

  useEffect(() => {
      if (_update_income.status === 200) {
        dispatch(clearIncome(UPDATE_INCOME));  
        dispatch(getAllVisitsAndDepartures(token, 1));
        dispatch(getIncomeDetails(token, id));
        setIsLoading(false);
      } else if (_update_income.status !== 0) {
        toast.error(_update_income.message);
        dispatch(clearIncome(UPDATE_INCOME));
        setIsLoading(false);
      }
  }, [_update_income, dispatch, token, id]);


  const handleClickAprove = (type) => {
      setIsLoading(true);
      let data = {estado: type}
      dispatch(updateIncome(token, DataSelected.id, data));
  }

  return (
    <div className='content-wrapper' style={{ background: "#F2F4F3", overflowY: 'auto' }}>
      <div className='content-body'>
        <ToastContainer/>
        {isLoading && <LoadingSpinner/>}
        <div className='app-content-overlay' />
        <div className='email-app-area'>
          <div className='email-app-list-wrapper'>
            <div className='email-app-list'></div>
          </div>
          <div
            className='navbar navbar-light bg-white p-2'
            style={{
              borderBottom: "1px solid #DFE3E",
            }}
          >
            <div className='email-header-left d-flex align-items-center mb-1'>
              <div to='/' className='go-back mr-50' onClick={setOpen}>
                <span className='fonticon-wrap d-inline'>
                  <i className='bx bx-arrow-back' />
                </span>
              </div>
              <h5 className='email-detail-title font-weight-normal mb-0'>
                {DataSelected.establecimiento}
              </h5>
            </div>
            <div className='email-header-right mb-1 ml-2 pl-1'>
              <span
                className={`badge badge-light-${get_status(
                  DataSelected.estado
                )} badge-pill ml-1`}
              >
                {DataSelected.estado}
              </span>
            </div>
          </div>
          <div className='email-scroll-area'>
            <div className='row'>
              <div className='col-12 p-10'>
                <div className='collapsible email-detail-head'>
                  <Card
                    //establishment_name
                    title={DataSelected.establecimiento}
                    description={
                      <>
                        <span>Fecha de apertura: </span>
                        <strong>
                          {new Date(
                            DataSelected.fecha_apertura
                          ).toLocaleDateString("es-ES", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </strong>
                        <br />
                      </>
                    }
                  >
                    <form className='row'>
                      {DataSelected.fecha_solicitud_apertura && (
                        <div className='col-12 mb-0'>
                          <h6>Fecha de solicitud de apertura:</h6>
                          {DataSelected.fecha_solicitud_apertura}
                        </div>
                      )}
                      {DataSelected.fecha_respuesta_apertura && (
                        <div className='col-12 mb-0'>
                          <h6>Fecha de respuesta de apertura:</h6>
                          {DataSelected.fecha_respuesta_apertura}
                        </div>
                      )}
                      {DataSelected.fecha_aprobacion && (
                        <div className='col-12 mb-0'>
                          <h6>Fecha de aprobación:</h6>
                          {DataSelected.fecha_aprobacion}
                        </div>
                      )}
                    </form>
                    <div className="col-12 d-flex justify-content-end mt-5">
                        {
                            (UserID.approve_income.codigo === "approve_income" && DataSelected.estado === "INGRESO REQUERIDO") && (
                                <Button
                                    text="Aprobar"
                                    onClick={
                                        () => {
                                            handleClickAprove("INGRESO ACEPTADO");
                                        }
                                    }
                                    theme="success ml-2"
                                    type="submit"
                                />
                            )
                        }
                        {/* {
                            (UserID.response_income.codigo === "response_income" && DataSelected.estado === "PENDIENTE APROBAR") && (
                                <Button
                                    text="Aprobar ingreso"
                                    onClick={
                                        () => {
                                            handleClickAprove("INGRESO APROBADO");
                                        }
                                    }
                                    theme="success ml-2"
                                    type="submit"
                                />
                            )
                        } */}
                    </div>
                  </Card>
                </div>
              </div>
            </div>
            {/* email details  end*/}
            <div className='row px-2 mb-4'>
              <div className='col-12 px-0' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IncomeDetail;
