import VisitasAdmin from "./admin/visits/index";
import SalidasAdmin from "./admin/departures/index";
import List from "../pages/admin/visits/list";
import DepartureList from "../pages/admin/departures/list";
import Companies from "../pages/admin/company/index";
import CreateCompany from "../pages/admin/company/create";
import UpdateCompany from "../pages/admin/company/update";
import Reasons from "../pages/admin/departure_reasons/index";
import CreateReason from "../pages/admin/departure_reasons/create";
import UpdateReason from "../pages/admin/departure_reasons/update";
import Establishments from "../pages/admin/establishment/index";
import CreateEstablishment from "../pages/admin/establishment/create";
import UpdateEstablishment from "../pages/admin/establishment/update";
import Employees from "../pages/admin/employee/index";
import LogsEmployees from "../pages/admin/employee/logs";
import CreateEmployee from "../pages/admin/employee/create";
import Import from '../pages/admin/employee/import';
import UpdateEmployee from "../pages/admin/employee/update";
import Supplier from "../pages/admin/supplier/index";
import UpdateSupplier from '../pages/admin/supplier/update';
import CreateSupplier from "../pages/admin/supplier/create";
import Visitant from "../pages/admin/visitant/index";
import UpdateVisitant from "../pages/admin/visitant/update";
import CreateVisitant from "../pages/admin/visitant/create";
import Users from "../pages/admin/users/index";
import CreateUser from "../pages/admin/users/create";
import UpdateUser from "../pages/admin/users/update";
import Jobs from '../pages/admin/job/index';
import CreateJob from '../pages/admin/job/create';
import VisitDetails from '../pages/admin/visits/details';
import DepartureDetails from '../pages/admin/departures/details';
import SalidasMonitoreo from '../pages/admin/departures/index-monitoring';
import UpdateJob from '../pages/admin/job/update';
import GroupList from '../pages/admin/groups/index';
import CreateGroup from '../pages/admin/groups/create';
import UpdateGroup from '../pages/admin/groups/update';
import EmailZonesList from '../pages/admin/email_zones/index';
import UpdateEmailZone from '../pages/admin/email_zones/update';
import Zones from "../pages/admin/zones/index";
import CreateZone from "../pages/admin/zones/create";
import UpdateZone from "../pages/admin/zones/update";
import EmailSettingList from "../pages/admin/email_settings";
import IncomeAdmin from "../pages/admin/income/index"
import IncomeList from "../pages/admin/income/list"
import IncomeDetails from '../pages/admin/income/details';
import ReasonsDeclineList from "../pages/admin/reasons_decline/index"
import BanksList from "../pages/admin/banks/index";

// import Help from '../pages/admin/help/index'
// import HelpDetail from '../pages/admin/help/detail'

const routes = [
    {
        path: "/",
        name: "salidas Admin",
        exact: true,
        component: SalidasAdmin,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Salidas",
            permission_required: [
                'view_employeedepartures' &&
                'aprove_departures'
            ],
        },
    },
    {
        path: "/",
        name: "salidas",
        exact: true,
        component: DepartureList,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Salidas",
            permission_required: [
                'add_employeedepartures',
            ],
        },
    },
    {
        path: "/",
        name: "visitas-salidas-monitoreo",
        exact: true,
        component: SalidasMonitoreo,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Monitoreo",
            permission_required: [
                'response_exit', "can_approve_visit"
            ],
        },
    },
    {
        path: "/salidas/:departure_id",
        name: "salidas",
        exact: true,
        component: DepartureDetails,
        show_in_menu: false,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Salidas",
            permission_required: [
                "view_employeedepartures",
                
            ],
        },
    },
    {
        path: "/ingresos-tiendas",
        name: "ingresos",
        exact: true,
        component: IncomeAdmin,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Ingresos a tiendas",
            permission_required: [
                "view_income"
            ],
        },
    },
    {
        path: "/ingreso-tienda",
        name: "ingresos",
        exact: true,
        component: IncomeList,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Ingresos a tiendas",
            permission_required: [
                "add_income"
            ],
        },
    },
    {
        path: "/ingreso-tienda/:id",
        name: "ingresos",
        exact: true,
        component: IncomeDetails,
        show_in_menu: false,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Ingresos a tiendas",
            permission_required: [
                "add_income"
            ],
        },
    },
    {
        path: "/visitas",
        name: "visitas",
        exact: true,
        component: VisitasAdmin,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Visitas",
            permission_required: [
                "add_visits",
                'can_approve_visit' &&
                'aprove_departures'
            ],
        },
    },
    {
        path: "/visitas",
        name: "visitas",
        exact: true,
        component: List,
        show_in_menu: true,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Visitas",
            permission_required: [
                'can_add_no_scheduled_visit',
            ],
        },
    },
    {
        path: "/visitas/:id",
        name: "visitas",
        exact: true,
        component: VisitDetails,
        show_in_menu: false,
        icon: "bx bx-home-alt",
        meta: {
            requiresAuth: true,
            title: "Visitas",
            permission_required: [
                "view_visits",
                "can_add_no_scheduled_visit"
            ],
        },
    },
    {
        path: "/empresas",
        name: "empresas",
        exact: true,
        component: Companies,
        icon: "bx bxs-city",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Empresas",
            permission_required: [
                "view_company",
            ],
        },
    },
    {
        path: "/empresas/crear",
        name: "empresas",
        exact: true,
        component: CreateCompany,
        icon: "bx bxs-city",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empresas",
            permission_required: [
                "add_company",
            ],
        },
    },
    {
        path: "/empresas/editar/:id",
        name: "empresas",
        exact: true,
        component: UpdateCompany,
        icon: "bx bxs-city",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empresas",
            permission_required: [
                "change_company",
            ],
        },
    },
    {
        path: "/razones",
        name: "razones",
        exact: true,
        component: Reasons,
        icon: "bx bxs-book",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Razones",
            permission_required: [
                "add_reasons",
            ],
        },
    },
    {
        path: "/razones/crear",
        name: "razones",
        exact: true,
        component: CreateReason,
        icon: "bx bxs-city",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Razones",
            permission_required: [
                "add_reasons",
            ],
        },
    },
    {
        path: "/razones/editar/:id",
        name: "razones",
        exact: true,
        component: UpdateReason,
        icon: "bx bxs-city",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Razones",
            permission_required: [
                "add_reasons",
            ],
        },
    },
    {
        path: "/zonas-correos",
        name: "correos",
        exact: true,
        component: EmailZonesList,
        icon: "bx bx-mail-send",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Zonas - Correos",
            permission_required: [
                "view_email",
            ],
        },
    },
    {
        path: "/zona-correo/editar/:id",
        name: "correos",
        exact: true,
        component: UpdateEmailZone,
        icon: "bx bx-mail-send",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas - Correos",
            permission_required: [
                "change_email",
            ],
        },
    },
    {
        path: "/zonas",
        name: "zonas",
        exact: true,
        component: Zones,
        icon: "bx bx-map-pin",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "view_zones",
            ],
        },
    },
    {
        path: "/zona/crear",
        name: "zonas",
        exact: true,
        component: CreateZone,
        icon: "bx bx-map-pin",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "add_zones",
            ],
        },
    },
    {
        path: "/zona/editar/:id",
        name: "zonas",
        exact: true,
        component: UpdateZone,
        icon: "bx bx-map-pin",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Zonas",
            permission_required: [
                "change_zones",
            ],
        },
    },
    {
        path: "/cargos",
        name: "cargos",
        exact: true,
        component: Jobs,
        icon: "bx bxs-briefcase",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Cargos",
            permission_required: [
                "view_job",
            ],
        },
    },
    {
        path: "/cargos/crear",
        name: "cargos",
        exact: true,
        component: CreateJob,
        icon: "bx bxs-briefcase",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Cargos",
            permission_required: [
                "add_job",
            ],
        },
    },
    {
        path: "/cargos/editar/:id",
        name: "cargos",
        exact: true,
        component: UpdateJob,
        icon: "bx bxs-briefcase",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Cargos",
            permission_required: [
                "change_job",
            ],
        },
    },
    {
        path: "/establecimientos",
        name: "establecimientos",
        exact: true,
        component: Establishments,
        icon: "bx bxs-store-alt",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Establecimientos",
            permission_required: [
                "view_establishments",
            ],
        },
    },
    {
        path: "/establecimientos/crear",
        name: "establecimientos",
        exact: false,
        component: CreateEstablishment,
        icon: "bx bxs-store-alt",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Establecimiento",
            permission_required: [
                "add_establishments",
            ],
        },
    },
    {
        path: "/Establecimientos/editar/:id",
        name: "establecimientos",
        exact: true,
        component: UpdateEstablishment,
        icon: "bx bxs-store-alt",
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Establecimiento",
            permission_required: [
                "change_establishments",
            ],
        },
    },
    {
        path: "/empleados",
        name: "empleados",
        exact: true,
        icon: "bx bxs-group",
        component: Employees,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "view_employees",
            ],
        },
    },
    {
        path: "/empleados/crear",
        name: "empleados",
        exact: false,
        icon: "bx bxs-group",
        component: CreateEmployee,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "add_employees",
            ],
        },
    },
    {
        path: "/empleados/importar",
        name: "empleados",
        exact: false,
        icon: "bx bxs-group",
        component: Import,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "add_employees",
            ],
        },
    },
    {
        path: "/empleados/editar/:id",
        name: "empleados",
        exact: true,
        icon: "bx bxs-group",
        component: UpdateEmployee,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Empleados",
            permission_required: [
                "change_employees",
            ],
        },
    },
    {
        path: "/historia-empleados",
        name: "historia-empleados",
        exact: true,
        icon: "bx bxs-user-detail",
        component: LogsEmployees,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Historia de empleados",
            permission_required: [
                "view_employees",
            ],
        },
    },
    {
        path: "/configuraciones-correos",
        name: "configuraciones-correos",
        exact: true,
        component: EmailSettingList,
        icon: "bx bx-mail-send",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Configuraciones de correos",
            permission_required: [
                "view_emailsettings",
            ],
        },
    },
    {
        path: "/proveedores",
        name: "proveedores",
        exact: true,
        icon: "bx bxs-truck",
        component: Supplier,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "view_supplier",
            ],
        },
    },
    {
        path: "/proveedores/crear",
        name: "proveedores",
        exact: false,
        icon: "bx bxs-truck",
        component: CreateSupplier,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "add_supplier",
            ],
        },
    },
    {
        path: "/proveedores/editar/:id",
        name: "proveedores",
        exact: true,
        icon: "bx bxs-truck",
        component: UpdateSupplier,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Proveedores",
            permission_required: [
                "change_supplier",
            ],
        },
    },
    {
        path: "/invitados",
        name: "invitados",
        exact: true,
        icon: "bx bxs-user-detail",
        component: Visitant,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Invitados",
            permission_required: [
                "view_visitant",
            ],
        },
    },
    {
        path: "/invitados/crear",
        name: "invitados",
        exact: false,
        icon: "bx bxs-user-detail",
        component: CreateVisitant,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Invitados",
            permission_required: [
                "add_visitant",
            ],
        },
    },
    {
        path: "/invitados/editar/:id",
        name: "invitados",
        exact: true,
        icon: "bx bxs-user-detail",
        component: UpdateVisitant,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Invitados",
            permission_required: [
                "change_visitant",
            ],
        },
    },
    {
        path: "/motivos-rechazos",
        name: "motivos-rechazos",
        exact: true,
        component: ReasonsDeclineList,
        icon: "bx bxs-notepad",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Motivo de rechazo",
            permission_required: [
                "view_reasonsdecline",
            ],
        },
    },
    {
        path: "/bancos",
        name: "bancos",
        exact: true,
        component: BanksList,
        icon: "bx bxs-notepad",
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Bancos",
            permission_required: [
                "view_banks",
            ],
        },
    },
    {
        path: "/roles",
        name: "roles",
        icon: "bx bxs-user-pin",
        exact: true,
        component: GroupList,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "view_group",
            ],
        },
    },
    {
        path: "/roles/crear",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: CreateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "add_group",
            ],
        },
    },
    {
        path: "/roles/editar/:id",
        name: "roles",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateGroup,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Roles",
            permission_required: [
                "change_group",
            ],
        },
    },
    {
        path: "/usuarios",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: Users,
        show_in_menu: true,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "view_user",
            ],
        },
    },
    {
        path: "/usuarios/crear",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: CreateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "add_user",
            ],
        },
    },
    {
        path: "/usuarios/editar/:id",
        name: "usuarios",
        icon: "bx bxs-user",
        exact: true,
        component: UpdateUser,
        show_in_menu: false,
        meta: {
            requiresAuth: true,
            title: "Usuarios",
            permission_required: [
                "change_user",
            ],
        },
    },
    /*{
      path: "/ayuda/",
      name: "ayuda",
      icon: "bx bxs-info-circle",
      exact: true,
      component: Help,
      show_in_menu: true,
      meta: {
        requiresAuth: false,
        title: "Ayuda",
        permission_required: [
          "change_user",
        ],
      },
    },
    {
      path: "/detalle/:id",
      name: "ayuda",
      icon: "bx bxs-info-circle",
      exact: true,
      component: HelpDetail,
      show_in_menu: false,
      meta: {
        requiresAuth: false,
        title: "Ayuda",
        permission_required: [
          "change_user",
        ],
      },
    }*/
];

export default routes;
