import React from "react";

export const theme = (state) => {
  switch (state) {
    case "CREADO":
      return {
        backgroundColor: "#40ADF4",
        buttonColor: "#1A97F0",
      };
    case "INGRESO REQUERIDO":
      return {
        backgroundColor: "#FFCA3A",
        buttonColor: "#e1b038",
      };
    case "INGRESO APROBADO":
      return {
        backgroundColor: "#0dcaf0",
        buttonColor: "#0fa3c0",
      };
    case "INGRESO ACEPTADO":
      return {
        backgroundColor: "#48D7A4",
        buttonColor: "#24CB8C",
      };
    case "PENDIENTE APROBAR":
      return {
        backgroundColor: "#FFCA3A",
        buttonColor: "#e1b038",
      };
    default:
      return {
        backgroundColor: "#40ADF4",
        buttonColor: "#1A97F0",
      };
  }
};

const CardIncome = ({
  theme = {
    backgroundColor: "#40ADF4",
    buttonColor: "#1A97F0",
  },
  title,
  subtitle,
  description,
  button_text,
  onClick,
}) => {
  return (
    <div
      className={`card`}
      style={{ width: "18rem", backgroundColor: theme.backgroundColor }}
    >
      <div className='card-body text-white'>
        <h5 className='card-title text-white text-nowrap text-truncate '>
          {title}
        </h5>
        <h6 className='card-subtitle mb-2 text-white'>{subtitle}</h6>
        <p className='card-text'>
          {description.length > 20
            ? description.substring(0, 20) + "..."
            : description}
        </p>
      </div>
      <div className='card-footer d-flex justify-content-center'>
        <button
          onClick={onClick}
          className={`btn btn-block`}
          style={{ backgroundColor: theme.buttonColor, color: "#fff" }}
        >
          {button_text}
        </button>
      </div>
    </div>
  );
};

export default CardIncome;
