import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';
import CheckBox from "../../../components/check-box";

import {
    clear_reason_decline, 
    createReasonDecline, 
    CREATE_REASON_DECLINE, 
    getAllReasonDecline, 
    updateReasonDecline, 
    UPDATE_REASON_DECLINE
} from "../../../redux/reason_decline";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const ReasonsDecline = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_reason_decline = useSelector((state) => state.reason_decline_module._get_all_reason_decline);
    const _create_reason_decline = useSelector((state) => state.reason_decline_module._create_reason_decline);
    const _update_reason_decline = useSelector((state) => state.reason_decline_module._update_reason_decline);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_reason_decline.data.datos) {
            setData(_get_all_reason_decline.data.datos);
        }
    }, [_get_all_reason_decline]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        descripcion: "",
        activo: "",
        id: 0
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_reasonsdecline').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "calendar",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({...form, descripcion: item.descripcion, activo: item.activo});
                    },
                })
            }
        }
        setActions(act);
    // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        if (e.target.name === 'activo') {
            setForm({
                ...form,
                [e.target.name]: e.target.checked,
            });
        } else {
            setForm({
                ...form,
                [e.target.name]: e.target.value,
            });
        }
    };

    useEffect(() => {
        if (_create_reason_decline.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_reason_decline(CREATE_REASON_DECLINE));
            dispatch(getAllReasonDecline(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_reason_decline.status !== 0) {
            dispatch(clear_reason_decline(CREATE_REASON_DECLINE));
            toast.error(_create_reason_decline.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_reason_decline, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_reason_decline.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_reason_decline(UPDATE_REASON_DECLINE));
            dispatch(getAllReasonDecline(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_reason_decline.status !== 0) {
            dispatch(clear_reason_decline(UPDATE_REASON_DECLINE));
            toast.error(_update_reason_decline.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_reason_decline, dispatch, history, token, id]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.descripcion === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id === 0) {
            dispatch(createReasonDecline(token, form));
        } else {
            console.log(form);
            dispatch(updateReasonDecline(token, id, form));
        }
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Motivo de rechazo", link: "/motivos-rechazos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_reasonsdecline').length > 0 ? 
                                    <Button type="button" text="Crear" theme="btn btn-success" onClick={() => {
                                        setModal(true); 
                                        setForm({...form, descripcion: ''}); 
                                        setId(0);
                                    }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "descripcion",
                                                label: "Descripción",
                                                type: "text",
                                            },
                                            {
                                                name: "activo",
                                                label: "Activo",
                                                type: "boolean",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main div-modal" show={modal}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <div className="modal-header">
                        <h5>Motivo de rechazo</h5>
                    </div>
                    <div className="modal-body p-2">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <Input
                                    id="descripcion"
                                    name="descripcion"
                                    label="Descripción"
                                    onChange={handleChange}
                                    value={form.descripcion}
                                />
                            </div>
                            {(id !== 0) &&
                                <div className="form-group">
                                    <CheckBox label="Activo" name="activo" id="activo" onChange={handleChange}state={form.activo}/>
                                </div> 
                            }
                            <div className="text-center">
                                <button type="button" className="btn btn-outline-dark" onClick={() => {
                                    setModal(false);
                                    setId(null);
                                    setForm({
                                        descripcion: "",
                                        id: id,
                                    })
                                }}> Cancelar
                                </button>
                                <Button type="submit" text="Guardar" theme="btn btn-success" classes="m-1"/>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default ReasonsDecline;
