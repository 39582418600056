import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../components/sidebar";
import Navbar from "../components/navbar";
import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import route from "./routes";
import { useSelector, useDispatch } from "react-redux";
import { get_all_companies } from "../redux/company";
import { get_all_establishments } from "../redux/establishment";
import {
  check_export_employee_status,
  clear_employee,
  GET_REPORT_EMPLOYEE,
  getAllEmployees,
  getLogsEmployees,
} from "../redux/employees";
import { getAllSuppliers } from "../redux/supplier";
import { SocketContext } from "../config/ws-client";
import { get_all_visitants } from "../redux/visitant";
import {
  get_all_users,
  get_all_groups,
  get_all_permisions,
} from "../redux/users";
import { get_all_general_settings } from "../redux/base";
import { getAllJobs } from "../redux/jobs";
import { getAllVisits, clearVisit, GET_REPORT, GET_REPORT_MONITORING } from "../redux/visits";
import { getAllDepartures, check_export_departures_status, clearDeparture, GET_REPORT_DEPARTURE} from "../redux/departures";
import { getAllVisitsAndDepartures } from "../redux/monitoringDeparturesAndVisits.js";
import { getAllIncome, clearIncome, GET_REPORT_INCOME, check_export_income_status } from "../redux/income.js";
// import {getAllEmailVisits} from '../redux/email_visits'
import { get_all_zones } from "../redux/zones";
import { toast } from "react-toastify";
import { save_notification, check_export_status, check_export_monitoring_status } from "../redux/visits";
import { getAllEmailSetting } from "../redux/email_setting.js";
import {
  TYPE_UPDATE_MESSAGE,
  TYPE_REPORT_GENERATE,
  TYPE_CREATE_MESSAGE,
  TYPE_REPORT_EMPLOYEE_GENERATE,
  TYPE_REPORT_MONITORING_GENERATE,
  TYPE_CREATE_DEPARTURE,
  TYPE_UPDATE_DEPARTURE,
  TYPE_REPORT_DEPARTURE_GENERATE,
  TYPE_INCOME_CREATE_MESSAGE,
  TYPE_INCOME_UPDATE_MESSAGE,
  TYPE_INCOME_REPORT_GENERATE,
} from "../config/ws-client";
import { getAllReasonDecline } from "../redux/reason_decline.js";
import { get_all_bank } from "../redux/banks.js";

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const _data_login = useSelector((state) => state.users_module.login);
  const _session_data = useSelector(
    (state) => state.users_module._session_data
  );
  const [permisos, setPermissions] = useState([]);
  const { socket } = useContext(SocketContext);
  const _get_notification = useSelector(
    (state) => state.visits_module._get_notification
  );

  const [token, setToken] = useState("");

  const request_exit = useSelector((state) =>
    state.users_module._session_data.data.grupo_permiso?.permisos.find(
      (obj) => obj.codigo === "request_exit"
    )
  );

  const response_exit = useSelector((state) =>
    state.users_module._session_data.data.grupo_permiso?.permisos.find(
      (obj) => obj.codigo === "response_exit"
    )
  );

  const aprove_departures = useSelector((state) =>
    state.users_module._session_data.data.grupo_permiso?.permisos.find(
      (obj) => obj.codigo === "aprove_departures"
    )
  );


  /* const view_employeedepartures = useSelector((state) =>
    state.users_module._session_data.data.grupo_permiso?.permisos.find(
      (obj) => obj.codigo === "view_employeedepartures"
    )
  ); */

  const userEstablishmentsList = useSelector(
    (state) => state.users_module._session_data.data
  );
  const userEstablishmentsStatus = useSelector(
    (state) => state.users_module._session_data.status
  );

  
  const audio = new Audio("/base/assets/audio/audio.mp3");

  useEffect(() => {
    if (_data_login.status >= 200) {
      dispatch(get_all_companies(_data_login.data.token));
      dispatch(get_all_establishments(_data_login.data.token));
      dispatch(getAllEmployees(_data_login.data.token));
      dispatch(getLogsEmployees(_data_login.data.token, '', 1));
      dispatch(getAllSuppliers(_data_login.data.token));
      dispatch(get_all_visitants(_data_login.data.token));
      dispatch(get_all_users(_data_login.data.token));
      dispatch(getAllJobs(_data_login.data.token));
      dispatch(get_all_groups(_data_login.data.token));
      dispatch(getAllVisits(_data_login.data.token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
      dispatch(get_all_permisions(_data_login.data.token));
      dispatch(get_all_general_settings(_data_login.data.token));
      // dispatch(getAllEmailVisits(_data_login.data.token))
      dispatch(get_all_zones(_data_login.data.token));
      dispatch(getAllVisitsAndDepartures(_data_login.data.token, 1));
      dispatch(getAllEmailSetting(_data_login.data.token));
      dispatch(getAllDepartures(_data_login.data.token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
      dispatch(getAllIncome(_data_login.data.token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
      dispatch(getAllReasonDecline(_data_login.data.token));
      dispatch(get_all_bank(_data_login.data.token));
      setToken(_data_login.data.token);
      // console.log(
      //   "userEstablishmentsList",
      //   userEstablishmentsList.establecimientos?.map((par) => par.id)
      // );
    } else {
      history.push("/");
    }

    if (JSON.stringify(_session_data.data) !== "{}") {
      if (_session_data.data.grupo_permiso) {
        if (_session_data.data.grupo_permiso.permisos) {
          setPermissions(_session_data.data.grupo_permiso.permisos);
        }
      }
    }
  }, [dispatch, _data_login, _session_data, history, userEstablishmentsList]);
  
  useEffect(() => {
    if (userEstablishmentsStatus >= 200) {
      dispatch(
        getAllDepartures(
          _data_login.data.token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      dispatch(
        getAllVisits(
          _data_login.data.token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      dispatch(
        getAllIncome(
          _data_login.data.token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
    }
  }, [
    _data_login.data.token,
    dispatch,
    userEstablishmentsList.establecimientos,
    userEstablishmentsStatus,
  ]);

  useEffect(() => {
    handleMessageWs();
    // eslint-disable-next-line
  }, [socket]);

  useEffect(() => {
    console.log(_session_data);
    // eslint-disable-next-line
  }, [socket]);

  // const sleep = (ms) => {
  //     return new Promise(resolve => setTimeout(resolve, ms));
  // }

  const handleMessageWs = () => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data.type === TYPE_UPDATE_MESSAGE) {
          visit_update_message(data, socket);
          return;
        }
        if (data.type === TYPE_REPORT_GENERATE) {
          visit_report_generate(data, socket);
        }
        if (data.type === TYPE_REPORT_EMPLOYEE_GENERATE) {
          console.log("generar empleados");
          employee_report_generate(data, socket);
        }
        if (data.type === TYPE_REPORT_DEPARTURE_GENERATE) {
          console.log("generar reporte de salida de empleados");
          departures_report_generate(data, socket);
        }
        if (data.type === TYPE_REPORT_MONITORING_GENERATE) {
          console.log("generar reporte de monitoreo");
          monitoring_report_generate(data, socket);
        }
        if (data.type === TYPE_CREATE_MESSAGE) {
          visit_create_message(data, socket);
        }
        if (data.type === TYPE_UPDATE_DEPARTURE) {
          departure_update_message(data);
          return;
        }
        if (data.type === TYPE_CREATE_DEPARTURE) {
          departure_create_message(data);
        }
        if (data.type === TYPE_INCOME_UPDATE_MESSAGE) {
          income_update_message(data);
          return;
        }
        if (data.type === TYPE_INCOME_CREATE_MESSAGE) {
          income_create_message(data);
        }
        if (data.type === TYPE_INCOME_REPORT_GENERATE) {
          console.log("generar reporte de monitoreo");
          income_report_generate(data, socket);
        }
      };
    }
  };

  const visit_update_message = (data, socket) => {
    toast.success(data.message);
    const notification = {
      title: data.title,
      message: data.message,
      result: null,
      read: false,
      type: TYPE_UPDATE_MESSAGE,
      show: true,
      date: data.date,
    };
    let n = _get_notification.data ?? [];
    n.push(notification);
    dispatch(save_notification(n));
    dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
    dispatch(getAllVisitsAndDepartures(token, 1));
    audio.play();
  };

  const visit_create_message = (data, socket) => {
    dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
    dispatch(getAllVisitsAndDepartures(token, 1));
  };

  const departure_update_message = (data, socket) => {
    dispatch(
      getAllDepartures(
        token,
        userEstablishmentsList.establecimientos?.map((par) => par.id),
        1
      )
    );
    dispatch(getAllVisitsAndDepartures(token, 1));
    if ((request_exit && request_exit.codigo === "request_exit") || (response_exit && response_exit.codigo === 'response_exit')) {
      toast.success(data.message);
      const notification = {
        title: data.title,
        message: data.message,
        result: null,
        read: false,
        type: TYPE_UPDATE_DEPARTURE,
        show: true,
        date: data.date,
      };
      let n = _get_notification.data ?? [];
      n.push(notification);
      dispatch(save_notification(n));
    }
    audio.play();
  };

  const departure_create_message = (data, socket) => {
    if (aprove_departures && aprove_departures.codigo === "aprove_departures") {
      toast.success("Nueva salida creada");
      const notification = {
        title: "Nueva salida creada",
        message: "",
        result: null,
        read: false,
        type: TYPE_CREATE_DEPARTURE,
        show: true,
      };
      let n = _get_notification.data ?? [];
      n.push(notification);
      dispatch(save_notification(n));
      audio.play();
      // console.log(data)
    } else {
      console.log(
        "userPermmission.aprove_departures not found",
        aprove_departures
      );
    }
    dispatch(
      getAllDepartures(
        token,
        userEstablishmentsList.establecimientos?.map((par) => par.id),
        1
      )
    );
    dispatch(getAllVisitsAndDepartures(token, 1));
  };

  const income_create_message = (data, socket) => {
    dispatch(
      getAllIncome(
        token,
        userEstablishmentsList.establecimientos?.map((par) => par.id),
        1
      )
    );
    dispatch(getAllVisitsAndDepartures(token, 1));
  };

  const income_update_message = (data, socket) => {
    toast.success(data.message);
    const notification = {
      title: data.title,
      message: data.message,
      result: null,
      read: false,
      type: TYPE_INCOME_UPDATE_MESSAGE,
      show: true,
      date: data.date,
    };
    let n = _get_notification.data ?? [];
    n.push(notification);
    dispatch(save_notification(n));
    dispatch(
      getAllIncome(
        token,
        userEstablishmentsList.establecimientos?.map((par) => par.id),
        1
      )
    );
    dispatch(getAllVisitsAndDepartures(token, 1));
    audio.play();
  };

  let timer_income = null;

  const income_report_generate = (data, socket) => {
    timer_income = setInterval(() => {
      check_export_income_status(token, data.id_task)
        .then((e) => {
          let datos = e.data;
          console.log(datos);
          if (e.status === 200) {
            dispatch(clearIncome(GET_REPORT_INCOME));
            const notification = {
              title: "Reporte Generado ",
              message: datos.result.nombre,
              result: datos.result,
              id: datos.id_task,
              read: false,
              date: datos.date,
              type: TYPE_INCOME_REPORT_GENERATE,
              show: true,
            };
            let n = _get_notification.data ?? [];
            n.push(notification);
            dispatch(save_notification(n));
            audio.play();
            clearInterval(timer_income);
            timer_income = null;
          }
        })
        .catch((e) => {
          clearInterval(timer_income);
          timer_income = null;
        });
    }, 5000);
  };

  let timer = null;

  const visit_report_generate = (data, socket) => {
    console.log("reporte");
    console.log(data.id_task);
    timer = setInterval(() => {
      check_export_status(token, data.id_task)
        .then((e) => {
          let datos = e.data;
          console.log(datos);
          if (e.status === 200) {
            dispatch(clearVisit(GET_REPORT));
            const notification = {
              title: "Reporte Generado ",
              message: datos.result.nombre,
              result: datos.result,
              id: datos.id_task,
              read: false,
              date: datos.date,
              type: TYPE_REPORT_GENERATE,
              show: true,
            };
            let n = _get_notification.data ?? [];
            n.push(notification);
            dispatch(save_notification(n));
            audio.play();
            clearInterval(timer);
            timer = null;
          }
        })
        .catch((e) => {
          clearInterval(timer);
          timer = null;
        });
    }, 5000);
  };

  let timer_employee = null;
  const employee_report_generate = (data, socket) => {
    timer_employee = setInterval(() => {
      check_export_employee_status(token, data.id_task)
        .then((e) => {
          let datos = e.data;
          if (e.status === 200) {
            dispatch(clear_employee(GET_REPORT_EMPLOYEE));
            const notification = {
              title: "Reporte Generado ",
              message: datos.result.nombre,
              result: datos.result,
              id: datos.id_task,
              read: false,
              date: datos.date,
              type: TYPE_REPORT_EMPLOYEE_GENERATE,
              show: true,
            };
            let n = _get_notification.data ?? [];
            n.push(notification);
            dispatch(save_notification(n));

            audio.play();
            clearInterval(timer_employee);
            timer_employee = null;
          }
        })
        .catch((e) => {
          clearInterval(timer_employee);
          timer_employee = null;
        });
    }, 5000);
  };


  let timer_departure = null;
  const departures_report_generate = (data, socket) => {
    timer_departure = setInterval(() => {
      check_export_departures_status(token, data.id_task)
        .then((e) => {
          let datos = e.data;
          if (e.status === 200) {
            dispatch(clearDeparture(GET_REPORT_DEPARTURE));
            const notification = {
              title: "Reporte Generado ",
              message: datos.result.nombre,
              result: datos.result,
              id: datos.id_task,
              read: false,
              date: datos.date,
              type: TYPE_REPORT_DEPARTURE_GENERATE,
              show: true,
            };
            let n = _get_notification.data ?? [];
            n.push(notification);
            dispatch(save_notification(n));

            audio.play();
            clearInterval(timer_departure);
            timer_departure = null;
          }
        })
        .catch((e) => {
          clearInterval(timer_departure);
          timer_departure = null;
        });
    }, 5000);
  };

  let timer_monitoring = null;
  const monitoring_report_generate = (data, socket) => {
    timer_monitoring = setInterval(() => {
      check_export_monitoring_status(token, data.id_task)
        .then((e) => {
          let datos = e.data;
          if (e.status === 200) {
            dispatch(clearVisit(GET_REPORT_MONITORING));
            const notification = {
              title: "Reporte Generado ",
              message: datos.result.nombre,
              result: datos.result,
              id: datos.id_task,
              read: false,
              date: datos.date,
              type: TYPE_REPORT_MONITORING_GENERATE,
              show: true,
            };
            let n = _get_notification.data ?? [];
            n.push(notification);
            dispatch(save_notification(n));

            audio.play();
            clearInterval(timer_monitoring);
            timer_monitoring = null;
          }
        })
        .catch((e) => {
          clearInterval(timer_monitoring);
          timer_monitoring = null;
        });
    }, 5000);
  };

  return (
    <Router>
      <div className='header-navbar-shadow' />
      <Navbar />
      <Sidebar />
      <Switch>
        {/*eslint-disable-next-line*/}
        {route.map((item, index) => {
          if (!item.meta.requiresAuth) {
            return (
              <Route path={item.path} exact={item.exact}>
                {<item.component {...permisos} />}
              </Route>
            );
          }

          if (
            permisos.filter((x) =>
              item.meta.permission_required.includes(x.codigo)
            ).length > 0
          ) {
            return (
              <Route path={item.path} exact={item.exact}>
                {<item.component {...permisos} />}
              </Route>
            );
          }
        })}
      </Switch>
    </Router>
  );
};
export default Index;
