import React, {useState, useEffect} from 'react';
import Input from '../../../components/input';
import TextArea from '../../../components/text-area';
import Async from 'react-select/async';
import {searchVisitant} from '../../../redux/visits';
import {useDispatch, useSelector} from "react-redux";
import ItemChat from "../../../components/item-chat";
import {URLAPI} from "../../../config";
import Button from "../../../components/button";
import {createVisit} from '../../../redux/visits';
import {toast, ToastContainer} from 'react-toastify';
import LoadingSpinner from "../../../components/loading-spinner";

const CreateVisitModal = (props) => {
    let dispatch = useDispatch();

    const {open, setOpen } = props
    const {token} = useSelector((state) => state.users_module.login.data) || null;
    const _create_visit = useSelector((state) => state.visits_module._create_visit);
    const [selectOptions, setSelectOptions] = useState([])
    const [establishment_select, setEstablishmentSelect] = useState(0)
    const [fecha, setFecha] = useState("")


    const init = {
        titulo: "",
        descripcion: "",
        observacion: "",
        id_establecimiento: 0,
        visita_programada: false,
        fecha: "",
        visitantes: [],

    };
    const [form, setForm] = useState(init)
    const [visitantsSelected, setVisitantSelected] = useState({
        visitantes: [],
        empleados: [],
        proveedores: [],
    });
    const _session_data = useSelector((state) => state.users_module._session_data);

    const [isLoading, setIsLoading] = useState(false);
    
    useEffect(() => {
        if (_create_visit.status === 201) {
            setForm(init)
            setVisitantSelected({
                visitantes: [],
                empleados: [],
                proveedores: [],
            })
            setSelectOptions([])
            handleClose();
        }
        setIsLoading(false);
        // eslint-disable-next-line
    }, [_create_visit, init])

    useEffect(() => {
        if (_session_data.data.establecimientos !== undefined) {
            if (_session_data.data.establecimientos.length !== 0) {
                let date = new Date();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let year = date.getFullYear();
                if (day < 10) {
                    day = "0" + day;
                }
                if (month < 10) {
                    month = "0" + month;
                }
                setFecha(year + "-" + month + "-" + day)
                setEstablishmentSelect(_session_data.data.establecimientos[0].id)
            }
        }
    }, [_session_data])


    const handleClose = () => {
        setOpen(!open)
    }
    const handleChangeSelect = (e) => {
        setSelectOptions(e)
        let empleados = []
        let visitantes = []
        let proveedores = []
        e.forEach(element => {
            if (element.type === "proveedor") {
                proveedores.push(element.item)
            }

            if (element.type === "empleado") {
                empleados.push(element.item)
            }

            if (element.type === "visitante") {
                visitantes.push(element.item)
            }
        });
        let com = [];
        if (empleados.length !== 0) {
            com = empleados.filter(x => x.visita_sin_aprobacion === true)
        }

        if (com.length !== 0) {
            if (proveedores.length !== 0) {
                toast.error("No se puede agregar otros visitantes que no tenga visita sin aprobación")
                proveedores = []
            }
            if (visitantes.length !== 0) {
                toast.error("No se puede agregar otros visitantes que no tenga visita sin aprobación")
                visitantes = []
            }
            if (com.length !== empleados.length) {
                toast.error("No se puede agregar otros visitantes que no tenga visita sin aprobación")
                empleados = com;
            }
        }

        setVisitantSelected({
            visitantes: visitantes,
            empleados: empleados,
            proveedores: proveedores
        });
    }
    const promiseOptions = (inputValue) => {

        if (inputValue.length === 0) {
            return Promise.resolve({options: []});
        }
        //evaluar si es un numero
        let param = "?"
        if (inputValue.match(/^[0-9]+$/)) {
            param += "ruc=" + inputValue
        }

        //evaluar si son letras
        if (inputValue.match(/^[a-zA-Z\s]+$/)) {
            param += "nombres=" + inputValue
        }
        param += "&visita_no_programada=true"


        return new Promise((resolve, reject) => {
            setTimeout(() => {
                searchVisitant(token, param).then((res) => {
                    let datos = []

                    res.data.forEach(element => {
                        datos.push({
                            value: element.id,
                            label: element.nombres,
                            type: element.tipo,
                            item: element
                        })
                    });
                    resolve(datos);

                }).catch((err) => {
                    reject(err);
                })
            }, 1000);
        })
    }

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        });
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        form.visitantes = [];
        visitantsSelected.empleados.forEach(element => {
            form.visitantes.push({
                tipo: "EMPLEADO",
                ruc: element.ruc
            });
        })

        visitantsSelected.visitantes.forEach(element => {
            form.visitantes.push({
                tipo: "VISITANTE",
                ruc: element.ruc
            });

        })

        visitantsSelected.proveedores.forEach(element => {
            form.visitantes.push({
                tipo: "PROVEEDOR",
                ruc: element.ruc
            });
        })

        form.id_establecimiento = establishment_select;
        form.fecha = fecha;

        dispatch(createVisit(token, form));
        setForm(init)

    }
    return (
        <div className={`modal fade text-left ${open ? 'show' : ''}`} id="inlineForm" tabindex="-1" role="dialog"
             aria-labelledby="myModalLabel33" aria-hidden={open ? 'true' : 'false'}
             style={{
                 display: open ? 'block' : ''
             }}
        >
            <div className="modal-dialog modal-dialog-scrollable" role="document">
                <div className="modal-content">
                    <ToastContainer/>
                    {isLoading && <LoadingSpinner/>}
                    <div className="modal-header">
                        <h4 className="text-bold-400">Nueva visita no programada </h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                onClick={() => handleClose()}>
                            <i className="bx bx-x"/>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit} style={{
                        overflowY: 'scroll'
                    }}>
                        <div className="modal-body container">
                            <div className="row">
                                <div className="col-md-6">
                                    <Input
                                        label="Título"
                                        name="titulo"
                                        type="text"
                                        value={form.titulo}
                                        onChange={handleChange}
                                        placeholder="Título"
                                        required={true}
                                    />
                                    <TextArea
                                        label="Motivo de la visita"
                                        name="descripcion"
                                        id="descripcion"
                                        onChange={handleChange}
                                        value={form.descripcion}
                                        rows={5}
                                        placeholder="Motivo de la visita"
                                    />
                                    <TextArea
                                        label="Observación"
                                        name="observacion"
                                        id="observacion"
                                        onChange={handleChange}
                                        value={form.observacion}
                                        rows={5}
                                        placeholder="Observación"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Visitantes</label>
                                        <Async
                                            value={selectOptions}
                                            isMulti={true}
                                            defaultOptions={false}
                                            loadOptions={promiseOptions}
                                            onChange={(e) => {
                                                handleChangeSelect(e)
                                            }}
                                        />
                                    </div>

                                    <ul className="chat-sidebar-list">
                                        {
                                            visitantsSelected.empleados.map((item, index) => {
                                                return (
                                                    <ItemChat title={"EMPLEADO"}
                                                              subtitle={item.apellidos ? item.nombres + " " + item.apellidos : item.nombres}
                                                              image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                              }
                                                              key={index}/>
                                                )
                                            })
                                        }
                                        {
                                            visitantsSelected.visitantes.map((item, index) => {
                                                return (
                                                    <ItemChat title={"VISITANTE"}
                                                              subtitle={item.apellidos ? item.nombres + " " + item.apellidos : item.nombres}
                                                              image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                              }
                                                              key={index}/>
                                                )
                                            })
                                        }
                                        {
                                            visitantsSelected.proveedores.map((item, index) => {
                                                return (
                                                    <ItemChat title={"PROVEEDOR"}
                                                              subtitle={item.apellidos ? item.nombres + " " + item.apellidos : item.nombres}
                                                              image={item.foto ? URLAPI + item.foto : window.location.origin + "/base/app-assets/images/profile/no_perfil.png"
                                                              } key={index}/>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>

                            </div>


                        </div>
                        <div className="modal-footer">
                            <Button type="submit" text="Guardar" theme="success" onClick={() => {
                            }}>Cerrar</Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateVisitModal;
