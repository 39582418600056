import React, {useEffect, useState} from "react";
import DataTable from "../../../components/data-table";
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import LoadingSpinner from '../../../components/loading-spinner';

import {
    clear_email_setting, 
    createEmailSetting, 
    CREATE_EMAIL_SETTING, 
    deleteEmailSetting, 
    DELETE_EMAIL_SETTING, 
    getAllEmailSetting, 
    updateEmailSetting, 
    UPDATE_EMAIL_SETTING
} from "../../../redux/email_setting";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EmailSetting = (props) => {
    let history = useHistory();
    let dispatch = useDispatch();

    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_email_setting = useSelector((state) => state.email_setting_module._get_all_email_setting);
    const _create_email_setting = useSelector((state) => state.email_setting_module._create_email_setting);
    const _update_email_setting = useSelector((state) => state.email_setting_module._update_email_setting);
    const _delete_email_setting = useSelector((state) => state.email_setting_module._delete_email_setting);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (_get_all_email_setting.data.datos) {
            setData(_get_all_email_setting.data.datos);
        }
    }, [_get_all_email_setting]);

    const [permisos, setPermisos] = useState([]);
    const [actions, setActions] = useState([]);
    const [modal, setModal] = useState(false);
    const [modal_delete, setModalDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState(0);

    const [form, setForm] = useState({
        correo: "",
        id: 0
    });

    useEffect(() => {
        if (props) {
            const array = Object.values(props);
            setPermisos(array);
        }
    }, [props]);

    useEffect(() => {
        let act = []
        if (permisos) {
            if (permisos.filter(x => x.codigo === 'change_emailsettings').length > 0) {
                act.push({
                    name: "edit",
                    label: "Edit",
                    icon: "bx bx-edit",
                    color: "primary",
                    onClick: (item) => {
                        setModal(true);
                        setId(item.id);
                        setForm({...form, correo: item.correo});
                    },
                })
            }
            if (permisos.filter(x => x.codigo === 'delete_emailsettings').length > 0) {
                act.push({
                    name: "delete",
                    label: "Eliminar",
                    icon: "bx bxs-trash",
                    color: "danger",
                    onClick: (item) => {
                        setModalDelete(true);
                        setId(item.id)
                    },
                })
            }
        }
        setActions(act);
    // eslint-disable-next-line
    }, [permisos, history]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    useEffect(() => {
        if (_create_email_setting.status === 201) {
            toast.success("Creado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_email_setting(CREATE_EMAIL_SETTING));
            dispatch(getAllEmailSetting(token));
            setIsLoading(false);
            setModal(false);
        } else if (_create_email_setting.status !== 0) {
            dispatch(clear_email_setting(CREATE_EMAIL_SETTING));
            toast.error(_create_email_setting.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_create_email_setting, dispatch, history, token, id]);

    useEffect(() => {
        if (_update_email_setting.status === 200) {
            toast.success("Actualizado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_email_setting(UPDATE_EMAIL_SETTING));
            dispatch(getAllEmailSetting(token));
            setIsLoading(false);
            setModal(false);
            setId(0);
        } else if (_update_email_setting.status !== 0) {
            dispatch(clear_email_setting(UPDATE_EMAIL_SETTING));
            toast.error(_update_email_setting.message, {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
        }
    }, [_update_email_setting, dispatch, history, token, id]);

    useEffect(() => {
        if (_delete_email_setting.status === 200) {
            toast.success("Eliminado correctamente", {position: toast.POSITION.TOP_RIGHT});
            dispatch(clear_email_setting(DELETE_EMAIL_SETTING));
            dispatch(getAllEmailSetting(token));
            setModalDelete(false);
            setId(0);
        } else if (_delete_email_setting.status !== 0) {
            dispatch(clear_email_setting(DELETE_EMAIL_SETTING));
            toast.error(_delete_email_setting.message);
        }
    }, [_delete_email_setting, history, token, dispatch]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (form.nombre === "") {
            toast.error("Debe ingresar el nombre", {position: toast.POSITION.TOP_RIGHT});
            setIsLoading(false);
            return;
        }
        if (id === 0) {
            dispatch(createEmailSetting(token, form));
        } else {
            dispatch(updateEmailSetting(token, id, form));
        }
    };

    const handleDelete = () => {
        dispatch(deleteEmailSetting(token, id));
    };

    return (
        <div className="app-content content">
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Lista"
                            items={[{label: "Configuraciones de correos", link: "/configuraciones-correos"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div><ToastContainer/></div>
                    <div className="card">
                        <div className="card-content">
                            <div className="card-body">
                                {
                                    permisos.filter(x => x.codigo === 'add_emailsettings').length > 0 ? 
                                    <Button type="button" text="Crear" theme="success" onClick={() => {
                                        setModal(true); 
                                        setForm({...form, correo: ''}); 
                                        setId(0);
                                    }}/> : null
                                }
                                <div className="table-responsive">
                                    <DataTable
                                        dataTable={data}
                                        columns={[
                                            {
                                                name: "fecha_creacion",
                                                label: "Fecha de creación",
                                                type: "text",
                                            },
                                            {
                                                name: "correo",
                                                label: "Correo",
                                                type: "text",
                                            },
                                        ]}
                                        actions={actions}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-main" show={modal} style={{width: "40%"}}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <form onSubmit={handleSubmit}>
                        <div className="modal-header">
                            <h5>Configuración de correo</h5>
                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <Input
                                    id="correo"
                                    name="correo"
                                    label="Correo"
                                    onChange={handleChange}
                                    value={form.correo}
                                />
                            </div>
                        </div>
                        <div className="text-center">
                            <button type="button" className="btn btn-outline-dark" onClick={() => {
                                setModal(false);
                                setId(null);
                                setForm({
                                    correo: "",
                                    id: id,
                                })
                            }}> Cancelar
                            </button>
                            <Button type="submit" text="Guardar" theme="success" classes="m-1"/>
                        </div>
                    </form>
                </div>
            </Modal>
            <Modal className="modal-main" show={modal_delete} style={{width: "40%"}}>
                {isLoading && <LoadingSpinner/>}
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="text-center">
                            <h5>¿Está seguro de eliminar el registro?</h5>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={() => {handleDelete()}}>
                            SI
                        </button>
                        <button type="button" className="btn btn-secondary" onClick={() => {setModalDelete(false)}}>
                            NO
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default EmailSetting;
