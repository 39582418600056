import {
    URLAPI,
    EMAIL_SETTING_PATH,
    EMAIL_SETTING_CREATE_PATH,
    EMAIL_SETTING_UPDATE_PATH,
    EMAIL_SETTING_DELETE_PATH,
} from "../config/index";
import {LOGOUT} from './users'
import axios from "axios";

const init = {
    _get_all_email_setting: {
        data: [],

        status: 0,
        message: {},
    },

    _create_email_setting: {
        data: {},
        status: 0,
        message: {},
    },
    _update_email_setting: {
        data: {},
        status: 0,
        message: {},
    },
    _delete_email_setting: {
        data: {},
        status: 0,
        message: {},
    },
};

const GET_ALL_EMAIL_SETTING = "GET_ALL_EMAIL_SETTING";
export const CREATE_EMAIL_SETTING = "CREATE_EMAIL_SETTING";
export const UPDATE_EMAIL_SETTING = "UPDATE_EMAIL_SETTING";
export const DELETE_EMAIL_SETTING = "DELETE_EMAIL_SETTING";

export const email_setting_module = (state = init, action) => {
    switch (action.type) {
        case GET_ALL_EMAIL_SETTING:
            return {
                ...state,
                _get_all_email_setting: action.payload,
            };
        case CREATE_EMAIL_SETTING:
            return {
                ...state,
                _create_email_setting: action.payload,
            };
        case UPDATE_EMAIL_SETTING:
            return {
                ...state,
                _update_email_setting: action.payload,
            };
        case DELETE_EMAIL_SETTING:
            return {
                ...state,
                _delete_email_setting: action.payload,
            };
        case LOGOUT:
            return init;
        default:
            return state;
    }
};

export const getAllEmailSetting = (token) => async (dispatch) => {
    try {
        const response = await axios.get(`${URLAPI}${EMAIL_SETTING_PATH}`, {
            headers: {
                Authorization: `Token ${token}`,
            },
        });
        dispatch({
            type: GET_ALL_EMAIL_SETTING,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: GET_ALL_EMAIL_SETTING,
                payload: {
                    data: [],
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: GET_ALL_EMAIL_SETTING,
                payload: {
                    data: [],
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};


export const createEmailSetting = (token, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.post(
            `${URLAPI}${EMAIL_SETTING_CREATE_PATH}`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: CREATE_EMAIL_SETTING,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: CREATE_EMAIL_SETTING,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: CREATE_EMAIL_SETTING,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const updateEmailSetting = (token, id, data) => async (dispatch) => {
    try {
        let form = new FormData();

        for (var key in data) {
            form.append(key, data[key]);
        }
        const response = await axios.patch(
            `${URLAPI}${EMAIL_SETTING_UPDATE_PATH}${id}/`,
            form,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: UPDATE_EMAIL_SETTING,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: UPDATE_EMAIL_SETTING,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: UPDATE_EMAIL_SETTING,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const deleteEmailSetting = (token, id) => async (dispatch) => {
    try {
        const response = await axios.delete(
            `${URLAPI}${EMAIL_SETTING_DELETE_PATH}${id}/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        dispatch({
            type: DELETE_EMAIL_SETTING,
            payload: {
                data: response.data,
                status: response.status,
                message: "",
            },
        });
    } catch (error) {
        if (error.response) {
            dispatch({
                type: DELETE_EMAIL_SETTING,
                payload: {
                    data: {},
                    status: error.response.status,
                    message: error.response.data.mensaje,
                },
            });
        } else {
            dispatch({
                type: DELETE_EMAIL_SETTING,
                payload: {
                    data: {},
                    status: 500,
                    message: error.message,
                },
            });
        }
    }
};

export const clear_email_setting = (type) => async (dispatch) => {
    dispatch({
        type: type,
        payload: {
            data: [],
            status: 0,
            message: {},
        },
    });
};
