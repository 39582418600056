import React, { useState, useEffect } from "react";
import CardColor, { theme } from "../../../components/card-color";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import CreateUpdateModal from "./create-update-modal";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import {
  clearDeparture,
  getAllDepartures,
  CREATE_SCHEDULED_DEPARTURE,
  CREATE_EMERGENCY_DEPARTURE,
  UPDATE_DEPARTURE,
} from "../../../redux/departures";
import LoadingSpinner from "../../../components/loading-spinner";

const List = () => {
  const { token } =
    useSelector((state) => state.users_module.login.data) || null;

  const _get_all_departures = useSelector(
    (state) => state.departures_module._get_all_departures
  );

  let dispatch = useDispatch();

  const _create_emergency_departure = useSelector(
    (state) => state.departures_module._create_emergency_departure
  );

  const _create_scheduled_departure = useSelector(
    (state) => state.departures_module._create_scheduled_departure
  );

  const _update_departure = useSelector(
    (state) => state.departures_module._update_departure
  );

  const userEstablishmentsList = useSelector(
    (state) => state.users_module._session_data.data
  );

  const history = useHistory();
  const [departures, setDepartures] = useState([]);
  const [departureToday, setDepartureToday] = useState([]);
  const [nextDepartures, setNextDepature] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalKey, setModalKey] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (_create_scheduled_departure.status === 201) {
      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      toast.success("Salida creada", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearDeparture(CREATE_SCHEDULED_DEPARTURE));
      setIsLoading(false);
      delayTimed();
    } else if (_create_scheduled_departure.status !== 0) {
      toast.error(_create_scheduled_departure.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearDeparture(CREATE_SCHEDULED_DEPARTURE));
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [_create_scheduled_departure, dispatch, token, userEstablishmentsList]);

  useEffect(() => {
    if (_create_emergency_departure.status === 201) {
      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      toast.success("Salida creada", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearDeparture(CREATE_EMERGENCY_DEPARTURE));
      setIsLoading(false);
      delayTimed();
    } else if (_create_emergency_departure.status !== 0) {
      toast.error(_create_emergency_departure.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      dispatch(clearDeparture(CREATE_EMERGENCY_DEPARTURE));
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [_create_emergency_departure, dispatch, token, userEstablishmentsList]);

  useEffect(() => {
    if (_get_all_departures.data.datos) {
      if (_get_all_departures.data.datos.length > 0) {
        setDepartures(_get_all_departures.data.datos);
      }
    }
  }, [_get_all_departures]);

  // eslint-disable-next-line
  const today = new Date();

  useEffect(() => {
    if (departures.length > 0) {
      setDepartureToday(
        departures.filter(
          (departure) =>
            new Date(departure.date + " 00:00:00").toDateString() ===
            today.toDateString()
        )
      );
    }
    // eslint-disable-next-line
  }, [departures]);

  useEffect(() => {
    if (departures.length > 0) {
      // eslint-disable-next-line
      let lista = departures.filter((departure) => {
        let fecha = new Date(departure.date + " 00:00:00");
        if (fecha > today) {
          return departure;
        }
      });
      lista.sort((a, b) => {
        return new Date(a.date + " 00:00:00") - new Date(b.date + " 00:00:00");
      });
      setNextDepature(lista);
    }
    // eslint-disable-next-line
  }, [departures]);

  useEffect(() => {
    if (_update_departure.status === 200) {
      dispatch(clearDeparture(UPDATE_DEPARTURE));
      dispatch(
        getAllDepartures(
          token,
          userEstablishmentsList.establecimientos?.map((par) => par.id),
          1
        )
      );
      toast.success("Modificación registrada correctamente");
      dispatch(clearDeparture(UPDATE_DEPARTURE));
      setIsLoading(false);
    } else if (_update_departure.status !== 0) {
      toast.error(_update_departure.message);
      dispatch(clearDeparture(UPDATE_DEPARTURE));
      setIsLoading(false);
    }
  }, [_update_departure, token, dispatch, userEstablishmentsList]);

  const delayTimed = () => {
    setTimeout(() => {
      setModalKey((prevKey) => prevKey + 1);
      setOpenModal(!openModal);
    }, 5000);
  };

  return (
    <div className='app-content content'>
      <div className='content-overlay' />
      <ToastContainer />
      {isLoading && <LoadingSpinner/>}
      <div className='content-wrapper'>
        <div className='content-header row'>
          <div className='content-header-left col-12 mb-2 mt-1'>
            <Breadcrumbs
              title='Salidas'
              items={[{ label: "Listado Salidas", link: "/" }]}
            />
          </div>
        </div>
        <div className='content-body'>
          <div className='row d-flex justify-content-end'>
            <Button
              theme='success'
              text='Crear'
              onChange={() => setOpenModal(!openModal)}
            ></Button>
          </div>
          <div className='row'>
            <div className='col-12'>
              <h3 className='text-bold-400'>HOY</h3>
            </div>

            <div className='row mt-2 col'>
              {departureToday.map((departure, index) => {
                return (
                  <div className='col col-xl-3 col-md-4 col-sm-6 col-xs-12'>
                    <CardColor
                      theme={theme(departure.state_description)}
                      title={departure.reason_details}
                      subtitle={new Date(
                        departure.date + " 00:00:00"
                      ).toLocaleDateString()}
                      description={departure.state_description}
                      button_text='Ver'
                      estado={departure.state_description}
                      onClick={() => {
                        history.push(`/salidas/${departure.departure_id}`);
                      }}
                      //   onClickOclock={() => {
                      //     dispatch(
                      //       updateDeparture(token, departure.departure_id)
                      //     );
                      //   }}
                    />
                  </div>
                );
              })}
            </div>
            <div className='col-12'>
              <h3 className='text-bold-400'>PRÓXIMAS SALIDAS</h3>
            </div>

            <div className='row mt-2 col'>
              {nextDepartures.map((departure, index) => {
                return (
                  <div className='col col-xl-3 col-md-4 col-sm-6 col-xs-12'>
                    <CardColor
                      theme={theme(departure.state_description)}
                      title={departure.reason_details}
                      subtitle={new Date(
                        departure.date + " 00:00:00"
                      ).toLocaleDateString()}
                      description={departure.state_description}
                      button_text='Ver'
                      estado={departure.state_description}
                      hora={departure.state_description}
                      onClick={() => {
                        history.push(`/salidas/${departure.departure_id}`);
                      }}
                      // onClickOclock={() => {
                      //   dispatch(
                      //     updateDeparture(token, departure.departure_id)
                      //   );
                      // }}
                    />
                  </div>
                );
              })}
            </div>
            <CreateUpdateModal
              open={openModal}
              setOpen={setOpenModal}
              key={modalKey}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
