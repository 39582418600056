import React, {useState, useEffect} from 'react';
import CardColor, {theme} from '../../../components/card-color';
import Button from '../../../components/button';
import {useHistory} from 'react-router-dom';
import Breadcrumbs from "../../../components/breadcrumbs";
import {useDispatch, useSelector} from "react-redux";
import CreateVisitModal from './create-visit-modal';
import {ToastContainer, toast} from 'react-toastify';
import {CREATE_VISIT, clearVisit, getAllVisits, updateVisit, UPDATE_VISIT} from '../../../redux/visits';
import LoadingSpinner from "../../../components/loading-spinner";

const List = () => {
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    const _get_all_visits = useSelector(
        (state) => state.visits_module._get_all_visits
    );

    let dispatch = useDispatch();
    let history = useHistory();

    const _create_visit = useSelector((state) => state.visits_module._create_visit);

    const [visits, setVisits] = useState([]);
    const [grupo_permiso, setGrupPermiso] = useState('');
    const [visitToday, setVisitToday] = useState([]);
    const [nextVisits, setNextVisits] = useState([]);
    const [openModal, setOpenModal] = useState(false);

    const userEstablishmentsList = useSelector(
        (state) => state.users_module._session_data.data
    );

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (_create_visit.status === 201) {
            dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            toast.success("Visita creada", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearVisit(CREATE_VISIT));
            setIsLoading(false);
        } else if (_create_visit.status !== 0) {
            toast.error(_create_visit.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            dispatch(clearVisit(CREATE_VISIT));
            setIsLoading(false);
        }
    }, [_create_visit, dispatch, token, userEstablishmentsList])

    useEffect(() => {
        if (_get_all_visits.data.datos) {
            if (_get_all_visits.data.datos.length > 0) {
                let filter = _get_all_visits.data.datos.filter(x => x.estado !== 'PENDIENTE VISITA ZONAL');
                setVisits(filter);
                setGrupPermiso(_get_all_visits.data.grupo_permiso)
            }
        }
    }, [_get_all_visits]);

    // eslint-disable-next-line
    const today = new Date();

    useEffect(() => {
        if (visits.length > 0) {
            setVisitToday(visits.filter(visit => new Date(visit.fecha_visita + ' 00:00:00').toDateString() === today.toDateString()));
        }
    // eslint-disable-next-line
    }, [visits]);

    useEffect(() => {
        if (visits.length > 0) {
            // eslint-disable-next-line
            let lista = visits.filter(visit => {
                let fecha = new Date(visit.fecha_visita + ' 00:00:00');
                if (fecha > today) {
                    return visit;
                }
                // if ((fecha.getMonth() > today.getMonth())) {
                //     return visit;
                // }
                // if (fecha.getDate() > today.getDate() && fecha.getMonth() >= today.getMonth()) {
                //     return visit;
                // }
                // if (fecha.getFullYear() > today.getFullYear()) {
                //     return visit;
                // }
            });
            lista.sort((a, b) => {
                return new Date(a.fecha_visita + ' 00:00:00') - new Date(b.fecha_visita + ' 00:00:00');
            })
            setNextVisits(lista);
        }
    // eslint-disable-next-line
    }, [visits]);

    const _update_visit = useSelector((state) => state.visits_module._update_visit);

    useEffect(() => {
        if (_update_visit.status === 200) {
            dispatch(getAllVisits(token, userEstablishmentsList.establecimientos?.map((par) => par.id), 1));
            toast.success("Fecha de salida registrada correctamente")
            dispatch(clearVisit(UPDATE_VISIT));
            setIsLoading(false);
        } else if (_update_visit.status !== 0) {
            toast.error(_update_visit.message);
            dispatch(clearVisit(UPDATE_VISIT));
            setIsLoading(false);
        }
    }, [_update_visit, dispatch, token, userEstablishmentsList])

    return (

        <div className="app-content content">
            <div className="content-overlay"/>
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Visitas"
                            items={[{label: "Listado Visitas", link: "/visitas"}]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row d-flex justify-content-end">
                        <Button theme="success" text="Crear" onChange={() => setOpenModal(!openModal)}>

                        </Button>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <h3 className="text-bold-400">
                                HOY
                            </h3>
                        </div>

                        <div className="row mt-2 col">
                            {
                                visitToday.map((visit, index) => {
                                    return (
                                        <div className="col col-xl-3 col-md-4 col-sm-6 col-xs-12">
                                            <CardColor
                                                theme={theme(visit.estado)}
                                                title={visit.titulo}
                                                subtitle={new Date(visit.fecha_visita + ' 00:00:00').toLocaleDateString()}
                                                description={visit.estado}
                                                button_text="Ver"
                                                onClick={() => {
                                                    history.push(`/visitas/${visit.id}`)
                                                }}
                                                estado={visit.estado}
                                                hora={visit.hora_salida}
                                                grupo_permiso ={grupo_permiso}
                                                onClickOclock={() => {
                                                        dispatch(updateVisit(token, visit.id, {hora_salida: true}));
                                                }}
                                            />
                                        </div>
                                    )
                                })
                            }

                        </div>
                        <div className="col-12">
                            <h3 className="text-bold-400">
                                PRÓXIMAS VISITAS
                            </h3>
                        </div>

                        <div className="row mt-2 col">
                            {
                                nextVisits.map((visit, index) => {
                                    return (
                                        <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">

                                            <CardColor
                                                theme={theme(visit.estado)}
                                                title={visit.titulo}
                                                subtitle={new Date(visit.fecha_visita + ' 00:00:00').toLocaleDateString()}
                                                description={visit.estado}
                                                button_text="Ver"
                                                onClick={() => {
                                                    history.push(`/visitas/${visit.id}`)
                                                }}
                                                estado={visit.estado}
                                                hora={visit.hora_salida}
                                                grupo_permiso ={grupo_permiso}
                                                onClickOclock={() => {
                                                    dispatch(updateVisit(token, visit.id, {hora_salida: true}));
                                                }}
                                            />
                                        </div>

                                    )
                                })
                            }

                        </div>
                        <CreateVisitModal open={openModal} setOpen={setOpenModal}/>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default List;
