import React from "react";
import { WEBSOCKET } from "./index";




export const socket = (username) => {
  if (username) {
    const socket = new WebSocket(
      WEBSOCKET+"/ws/websocket/"+username+"/"
    );

    return socket;
  }

  }
export const SocketContext = React.createContext({
  socket: socket(""),
});




export const TYPE_UPDATE_MESSAGE = "visit_update_message";
export const TYPE_CREATE_MESSAGE = "visit_create_message";
export const TYPE_REPORT_GENERATE = "visit_report_generate";
export const TYPE_REPORT_EMPLOYEE_GENERATE = "employee_report_generate";
export const TYPE_REPORT_DEPARTURE_GENERATE = "departure_report_generate";
export const TYPE_REPORT_MONITORING_GENERATE = "monitoring_report_generate";

export const TYPE_UPDATE_DEPARTURE = "departure_update_message";
export const TYPE_CREATE_DEPARTURE = "departure_create_message";

export const TYPE_INCOME_UPDATE_MESSAGE = "income_update_message";
export const TYPE_INCOME_CREATE_MESSAGE = "income_create_message";
export const TYPE_INCOME_REPORT_GENERATE = "income_report_generate";