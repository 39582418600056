import {
  URLAPI,
  INCOME_PATH,
  INCOME_CREATE_PATH,
  INCOME_UPDATE_PATH,
  REQUEST_OPENING_PATH,
  REQUEST_APPROVAL_PATH,
  INCOME_REPORT_PATH,
  INCOME_REPORT_CHECK_PATH,
  INCOME_DETAILS_PATH,
} from "../config";
import { LOGOUT } from "./users";
import axios from "axios";

const init = {
  _get_all_income: {
    data: [],
    status: 0,
    message: "",
  },
  _create_income: {
    data: {},
    status: 0,
    message: "",
  },
  _update_income: {
    data: {},
    status: 0,
    message: "",
  },
  _request_opening: {
    data: {},
    status: 0,
    message: "",
  },
  _request_approval: {
    data: {},
    status: 0,
    message: "",
  },
  _get_report_income: {
    data: {},
    status: 0,
    message: "",
  },
};

const GET_ALL_INCOME = "GET_ALL_INCOME";
export const CREATE_INCOME = "CREATE_INCOME";
export const UPDATE_INCOME = "UPDATE_INCOME";
export const REQUEST_OPENING = "REQUEST_OPENING";
export const REQUEST_APPROVAL = "REQUEST_APPROVAL";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_REPORT_INCOME = "GET_REPORT_INCOME";
export const GET_INCOME_DETAILS = "GET_INCOME_DETAILS";

export const income_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_INCOME:
      return {
        ...state,
        _get_all_income: action.payload,
      };
    case GET_INCOME_DETAILS:
      return {
        ...state,
        _get_income_details: action.payload,
      };
    case CREATE_INCOME:
      return {
        ...state,
        _create_income: action.payload,
      };
    case UPDATE_INCOME:
        return {
          ...state,
          _update_income: action.payload,
        };
    case REQUEST_OPENING:
      return {
        ...state,
        _request_opening: action.payload,
      };
    case REQUEST_APPROVAL:
      return {
        ...state,
        _request_approval: action.payload,
      };
    case GET_REPORT_INCOME:
      return {
        ...state,
        _get_report_income: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

/**
 * @function getAllIncome
 * @description Obtiene los ingresos haciendo una peticion a la API
 * @param {string} token - token de autenticacion
 * @async @return dispatch - dispatch de la accion
 */
export const getAllIncome = (token, data, page) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${INCOME_PATH}?lista_establecimiento=${data}&page=${page}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_INCOME,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: GET_ALL_INCOME,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_INCOME,
        payload: {
          data: [],
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const getIncomeDetails = (token, id) => async (dispatch) => {
    try{
      const response=await axios.get( `${URLAPI}${INCOME_DETAILS_PATH}${id}/`,
      {headers:{Authorization:`Token ${token}`}})
      dispatch({
        type: GET_INCOME_DETAILS,
        payload: {
          data: response.data,
          status: response.status,
          message: "",
        },
      });
        }catch(err){console.log(`ERROR:${err}`);throw err;}
  }

export const createIncome = (token, data) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${INCOME_CREATE_PATH}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: CREATE_INCOME,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: CREATE_INCOME,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_INCOME,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const updateIncome = (token, id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${INCOME_UPDATE_PATH}${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_INCOME,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: UPDATE_INCOME,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_INCOME,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const request_opening = (token, id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${REQUEST_OPENING_PATH}${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: REQUEST_OPENING,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: REQUEST_OPENING,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: REQUEST_OPENING,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const request_approval = (token, id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${REQUEST_APPROVAL_PATH}${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: REQUEST_APPROVAL,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: REQUEST_APPROVAL,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: REQUEST_APPROVAL,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const get_report_income = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(URLAPI + INCOME_REPORT_PATH, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    dispatch({
      type: GET_REPORT_INCOME,
      payload: {
        data: res.data,
        status: res.status,
        message: "",
      },
    });
  } catch (e) {
    dispatch({
      type: GET_REPORT_INCOME,
      payload: {
        data: {},
        status: 500,
        message: "Ocurrio un error al generar el reporte",
      },
    });
  }
};

//clear income
export const clearIncome = (type) => (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      status: 0,
      message: "",
    },
  });
};


export const check_export_income_status = async (token, task_id) => {
  return await axios.get(`${URLAPI}${INCOME_REPORT_CHECK_PATH}${task_id}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
