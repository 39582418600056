import React from "react";
import { URLAPI } from "../../../config";
import "./departeee-modal.css";

const DeparteeModal = ({
  show = false,
  children,
  className,
  style,
  onClick,
  handleClose,
  employee,
}) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName} onClick={onClick}>
      <div className="modal-dialog modal-dialog-scrollable" role="document">
      <div className='modal-content'>
        <div className='modal-header'>
          <h4>{employee?.employee_info.employee_full_name}</h4>
          <button className='close' onClick={handleClose}>
            <i className='bx bx-x' />
          </button>
        </div>
        <div className="modal-body container">
          <div className="row mt-1 mb-1">
            <div className="col-md-6 text-center">
              {
                employee?.employee_info.employee_photo ?
                    <img
                        src={URLAPI + employee?.employee_info.employee_photo}
                        width="100%"
                        height="70%"
                        alt="avatar"

                    /> : <img
                        src={window.location.origin + "/base/app-assets/images/profile/no_perfil.png"}
                        width="100%"
                        height="100%"
                        alt="avatar"
                    />

              }
            </div>
            <div className="col-md-6 mt-1">
              <p>{employee?.employee_info.employee_ruc}</p>
              <p>Empresa: {employee?.employee_info.employee_company}</p>
              <p>Cargo: {employee?.employee_info.employee_job}</p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default DeparteeModal;
