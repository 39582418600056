import React from "react";
/**  @module Components/Card */
/**
 * Toma un título, descripción, subtítulo, hijo, botón positivo y botón negativo y devuelve una tarjeta
 * con el título, subtítulo, descripción, hijo, botón positivo y botón negativo.
 * @param {string} title - Título de la tarjeta
 * @param {string} description - Descripción de la tarjeta
 * @param {string} subtitle - Subtítulo de la tarjeta
 * @param {element} child - Hijo de la tarjeta
 * @param {object} positivebutton - Objeto con la acción y el texto del botón positivo
 * @param {object} negativeButton - Objeto con la acción y el texto del botón negativo
 * @returns Un componente de la tarjeta
 *
 * @example
 * <Card
 *      title="Título de la tarjeta"
 *      description="Descripción de la tarjeta"
 *      subtitle="Subtítulo de la tarjeta"
 *      child={<h1>Hijo de la tarjeta</h1>}
 *      positivebutton={{action:()=>{},text:"Texto del botón positivo",theme:"success"}}
 *      negativeButton={{action:()=>{},text:"Texto del botón negativo",theme:"success"}}
 * />
 */

const Card = ({
                  title,
                  description,
                  subtitle,
                  children,
                  positivebutton = {action: null, text: "", theme: "success"},
                  negativeButton = {action: null, text: "", theme: "success"},
              }) => {
    return (
        <div className="card">
            <div className="card-content">
                <div className="card-body d-flex justify-content-between">
                    <h4 className="card-title">{title}</h4>
                    <p className="card-text">{subtitle}</p>
                </div>
                <div className="card-body m-0">
                    <p className="card-text">{description}</p>
                    {children}
                </div>
            </div>
            <div className="card-footer d-flex justify-content-between">
                {positivebutton.action && (
                    <button
                        className={`btn btn-${positivebutton.theme}`}
                        onClick={positivebutton.action}
                    >
                        {positivebutton.text}
                    </button>
                )}

                {negativeButton.action && (
                    <button
                        className={`btn btn-${negativeButton.theme}`}
                        onClick={negativeButton.action}
                    >
                        {negativeButton.text}
                    </button>
                )}
            </div>
        </div>
    );
};

export default Card;
