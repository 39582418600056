import React, { useEffect, useState } from "react";
import { get_status } from "../../../config";
import Card from "../../../components/card";
import {
  // updateDeparture,
  ApprovedDeniedDeparture,
  getDepartureDetails,
  update_absence_days,
  AceptDeclineDeparture,
} from "../../../redux/departures";
import {getAllVisitsAndDepartures} from "../../../redux/monitoringDeparturesAndVisits"
import { useDispatch, useSelector } from "react-redux";
import CardItem from "../../../components/card-item";
import Button from "../../../components/button";
import Modal from "../../../components/modal";
import Input from "../../../components/input";
import DeparteeModal from "./departee-modal";
import { URLAPI } from "../../../config";
//import TextArea from "../../../components/text-area";
import LoadingSpinner from "../../../components/loading-spinner";
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SelectInput from "../../../components/select";

const DepartureDetailsModal = (props, item) => {
  // eslint-disable-next-line
  const [visitantSelected, setVisitantSelected] = useState({
    ruc: "0000001",
    nombres: "Denis",
    apellidos: "TOBAR",
    empresa: "",
    puesto: "",
    foto: "/media/employees_photo/photo-1494790108377-be9c29b29330.jpeg",
  });
  const { departure_id, open, setOpen } = props;
  const dispatch = useDispatch();

  const { token } =
    useSelector((state) => state.users_module.login.data) || null;

  const UserPermmission = {
    aprove_departures: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "aprove_departures"
      )
    ),
    view_employeedepartures: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "view_employeedepartures"
      )
    ),
    response_exit: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "response_exit"
      )
    ),
    absence_days: useSelector((state) =>
      state.users_module._session_data.data.grupo_permiso.permisos.find(
        (obj) => obj.codigo === "absence_days"
      )
    ),
  };

  const _get_departure_details = useSelector(
    (state) => state.departures_module._get_departure_details
  );

  const initForm = {
    departure_id: 0,
    establishment_id: "Loading",
    establishment_name: "Loading",
    reason_description: "Loading",
    reason_decline_description: "Loading",
    reason_details: "Loading",
    status_description: "Loading",
    state_id: "Loading",
    absence_days: "",
    date: "Loading",
    date_approval: "",
    date_create: "",
    date_request_exit: null,
    date_request_return: null,
    date_response_exit: null,
    date_response_return: null,
    employee_info: {},
  };
  const [showUpdateAbsenceModal, setShowUpdateAbsenceModal] = useState(false);
  const [newAbsenceDays, setNewAbsenceDays] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [DataSelected, setDataSelected] = useState(initForm);
  useEffect(() => {
    dispatch(getDepartureDetails(token, departure_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    if (_get_departure_details.data.datos) {
      setDataSelected(_get_departure_details.data.datos[0]);
    }
  }, [_get_departure_details, departure_id, open]);

  // useEffect(() => {
  //   console.log("data:", DataSelected);
  // }, [DataSelected]);

  // const handleEnEspera = (e) => {
  //   e.preventDefault();
  //   setDataSelected({ ...DataSelected, status_description: "SALIDA REQUERIDA" });
  //   let data = { estado: "SALIDA REQUERIDA" };
  //   dispatch(updateDeparture(token, DataSelected.departure_id, data));
  // };

  const _get_all_reason_decline = useSelector((store) => store.reason_decline_module._get_all_reason_decline);

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //const [observation, setObservation] = useState('');
  const [reasonsDeclide, setReasonsDecline] = useState([]);
  const [idReasonDeclide, setIdReasonDecline] = useState(0);
  
  useEffect(() => {
    if (_get_all_reason_decline && _get_all_reason_decline.data && _get_all_reason_decline.data.datos){
      let datas = _get_all_reason_decline.data.datos.filter(x => x.activo === true);
      setReasonsDecline(datas);
    }
    
  }, [_get_all_reason_decline])

  const handleReject = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (idReasonDeclide === 0) {
        toast.error("Debe seleccionar un motivo", {position: toast.POSITION.TOP_RIGHT});
        setIsLoading(false);
        return;
    }
    if (DataSelected.status_description === "SALIDA REQUERIDA") {
      setDataSelected({
        ...DataSelected,
        status_description: "SALIDA RECHAZADA",
      });
      let data = { estado: "SALIDA RECHAZADA", id_reason_decline: idReasonDeclide };
      dispatch(AceptDeclineDeparture(token, DataSelected.departure_id, data));
      setModal(false);
      setIsLoading(false);
      dispatch(getAllVisitsAndDepartures(token, 1));
    } else if (DataSelected.status_description === "CREADO") {
      setDataSelected({
        ...DataSelected,
        status_description: "SALIDA DENEGADA",
      });
      let data = { estado: "SALIDA DENEGADA", id_reason_decline: idReasonDeclide };
      dispatch(ApprovedDeniedDeparture(token, DataSelected.departure_id, data));
      setModal(false);
      setIsLoading(false);
      dispatch(getAllVisitsAndDepartures(token, 1));
    } else if (DataSelected.status_description === "RETORNO REQUERIDO") {
      setDataSelected({
        ...DataSelected,
        status_description: "RETORNO RECHAZADO",
      });
      let data = { estado: "RETORNO RECHAZADO", id_reason_decline: idReasonDeclide };
      dispatch(AceptDeclineDeparture(token, DataSelected.departure_id, data));
      setModal(false);
      setIsLoading(false);
      dispatch(getAllVisitsAndDepartures(token, 1));
    } else {
      setIsLoading(false);
    };
  };

  const handleApprove = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (DataSelected.status_description === "SALIDA REQUERIDA") {
      setDataSelected({
        ...DataSelected,
        status_description: "SALIDA ACEPTADA",
      });
      let data = { estado: "SALIDA ACEPTADA" };
      dispatch(AceptDeclineDeparture(token, DataSelected.departure_id, data));
      setIsLoading(false);
      dispatch(getAllVisitsAndDepartures(token, 1));
    } else if (DataSelected.status_description === "CREADO") {
      setDataSelected({
        ...DataSelected,
        status_description: "SALIDA APROBADA",
      });
      let data = { estado: "SALIDA APROBADA" };
      dispatch(ApprovedDeniedDeparture(token, DataSelected.departure_id, data));
      setIsLoading(false);
      dispatch(getAllVisitsAndDepartures(token, 1));
    } else if (DataSelected.status_description === "RETORNO REQUERIDO") {
      setDataSelected({
        ...DataSelected,
        status_description: "RETORNO ACEPTADO",
      });
      let data = { estado: "RETORNO ACEPTADO" };
      dispatch(AceptDeclineDeparture(token, DataSelected.departure_id, data));
      setIsLoading(false);
      dispatch(getAllVisitsAndDepartures(token, 1));
    } else console.error("handleApprove error");
  };

  const handleCloseModal = (e) => {
    setOpen();
  };

  useEffect(() => {
    setDataSelected(initForm); // Call setOpen as a function to close the modal
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log(UserPermmission); // Call setOpen as a function to close the modal
    // eslint-disable-next-line
  }, []);

  const handleUpdateAbsenceDays = () => {
    setShowUpdateAbsenceModal(true);
  };

  const handleConfirmUpdateAbsenceDays = (e) => {
    e.preventDefault();
    // Dispatch the update_absence_days action with the new absence days
    const data = { dias_ausencia: newAbsenceDays };
    dispatch(update_absence_days(token, DataSelected.departure_id, data))
      .then(() => {
        // Update DataSelected with the new absence days
        setDataSelected({
          ...DataSelected,
          absence_days: newAbsenceDays,
        });
        // Close the modal
        setShowUpdateAbsenceModal(false);
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error updating absence days:", error);
      });
    setNewAbsenceDays("");
  };

  const handleCancelUpdateAbsenceDays = () => {
    // Clear the input field and close the modal
    setNewAbsenceDays("");
    setShowUpdateAbsenceModal(false);
  };

  return (
    <div
      className='content-wrapper'
      style={
        // (open === false|| setDataSelected === initForm) ?  {display:"none"} :
        { background: "#FFF", overflowY: 'auto' }
      }
    >
      <div className='content-body'>
        <div><ToastContainer/></div>
        <div className='app-content-overlay' />
        <div className='email-app-area'>
          <div className='email-app-list-wrapper'>
            <div className='email-app-list'></div>
          </div>
          <div
            className='navbar navbar-light bg-white p-2 '
            style={{
              borderBottom: "1px solid #DFE3E",
            }}
          >
            <div className='email-header-left d-flex align-items-center mb-1'>
              <div
                to='/'
                className='go-back mr-50'
                onClick={handleCloseModal}
              >
                <span className='fonticon-wrap d-inline'>
                  <i className='bx bx-arrow-back' />
                </span>
              </div>
              <h5 className='email-detail-title font-weight-normal mb-0'>
                {DataSelected.reason_description}
              </h5>
            </div>
            <div
              className='email-header-right mb-1 ml-2 pl-1'
              style={{ padding: "0px" }}
            >
              <span
                className={`badge badge-light-${get_status(
                  DataSelected.status_description
                )} badge-pill ml-1`}
              >
                {DataSelected.status_description}
              </span>
            </div>
          </div>
          <div className="email-scroll-area mt-1">
            <div className='row'>
              <div className='col-12' style={{ fontSize: "smaller" }}>
                <div className='collapsible email-detail-head'>
                  <Card
                    title={DataSelected.establishment_name}
                    description={
                      <>
                        <h6>{DataSelected.reason_details}</h6>
                        <span>Fecha de salida: </span>
                        <strong>
                          {new Date(
                            DataSelected.date + " 00:00:00"
                          ).toLocaleDateString("es-ES", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}
                        </strong><br/>
                        {"Tipo de salida: "}
                        <strong>{DataSelected.reason_description}</strong>{" "}
                      </>
                    }
                  >
                    <form className='row'>
                      {DataSelected.date_approval && (
                        <div className='col-12 mb-1'>
                          <h6>Fecha de solicitud de aprobación: </h6>
                          {DataSelected.date_approval}
                        </div>
                      )}
                      {DataSelected.fecha_respuesta_aprobacion && (
                        <div className='col-12 mb-1'>
                          <h6>Fecha de aprobación: </h6>
                          {DataSelected.fecha_respuesta_aprobacion}
                        </div>
                      )}
                      <div className='col-md-12 col-12 mb-1'>
                        <strong style={{color:"#475F7B"}}>Observación: </strong>
                        {DataSelected.reason_details} 
                      </div>
                      {DataSelected.reason_decline_description && (
                        <div className='col-12 mb-1'>
                          <strong style={{color:"#475F7B"}}>Motivo de rechazo: </strong>
                          {DataSelected.reason_decline_description}
                        </div>                
                      )}

                      {DataSelected.employee_info !== undefined ? (
                        <div className='col col-sm-12 col-md-6'>
                          <CardItem
                            data-toggle='modal'
                            data-target='#departeeModal'
                            onClick={() => {
                              setVisitantSelected({
                                ...item,
                                empresa: "",
                                puesto: "",
                                cargo: "Visitante",
                              });
                              setIsModalVisible(true);
                            }}
                          >
                            <>
                              <div className=' d-flex'>
                                <div className='list-icon mr-1'>
                                  <div className='avatar bg-rgba-primary m-0'>
                                    <img
                                      className='img-fluid'
                                      src={
                                        URLAPI +
                                        DataSelected.employee_info
                                          .employee_photo
                                      }
                                      alt='img placeholder'
                                      height='38'
                                      width='38'
                                    />
                                  </div>
                                </div>
                                <div className='list-content'>
                                  <span className='list-title text-bold-500'>
                                    {
                                      DataSelected.employee_info
                                        .employee_full_name
                                    }
                                  </span>
                                  <small className='text-muted d-block'>
                                    Cargo:{" "}
                                    {DataSelected.employee_info.employee_job}
                                  </small>
                                  <small className='text-muted d-block'></small>
                                </div>
                              </div>
                            </>
                          </CardItem>
                        </div>
                      ) : null}
                    </form>
                    <form className='row'>
                      {DataSelected.date_create && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de creación: {" "}
                            <strong> {DataSelected.date_create}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_approval && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de aprobación: {" "}
                            <strong> {DataSelected.date_approval}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_request_exit && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de solicitud de apertura de salida: {" "}
                            <strong> {DataSelected.date_request_exit}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_response_exit && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Fecha de Respuesta de apertura de puerta Salida: {" "}
                            <strong> {DataSelected.date_response_exit}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_request_return && (
                        <div className='col-12 mb-0'>
                          <h6>
                            Solicitud de apertura de puerta retorno: {" "}
                            <strong> {DataSelected.date_request_return}</strong>{" "}
                          </h6>
                        </div>
                      )}
                      {DataSelected.date_response_return && (
                        <div className='col-12 mb-0' style={{ margin: "0px" }}>
                          <h6>
                            Solicitud de apertura de puerta retorno: {" "}
                            <strong>
                              {" "}
                              {DataSelected.date_response_return}
                            </strong>{" "}
                          </h6>
                        </div>
                      )}
                    </form>
                    {DataSelected.status_description === "SALIDA REQUERIDA" &&
                    UserPermmission.response_exit?.codigo ===
                      "response_exit" ? (
                      <div className='col-12 d-flex justify-content-end'>
                        <Button
                          text='Denegar Apertura'
                          onClick={() => {setModal(true); setIdReasonDecline(0);}}
                          theme='danger'
                          type='button'
                        />
                        <p style={{ opacity: 0 }}>__</p>
                        <Button
                          text='Aprobar Apertura'
                          onClick={handleApprove}
                          theme='success'
                          type='button'
                        />
                      </div>
                    ) : DataSelected.status_description ===
                        "RETORNO REQUERIDO" &&
                      UserPermmission.response_exit?.codigo ===
                        "response_exit" ? (
                      <div className='col-12 d-flex justify-content-end'>
                        <Button
                          text='Denegar Retorno'
                          onClick={() => {setModal(true); setIdReasonDecline(0);}}
                          theme='danger'
                          type='button'
                        />
                        <p style={{ opacity: 0 }}>__</p>
                        <Button
                          text='Aprobar Retorno'
                          onClick={handleApprove}
                          theme='success'
                          type='button'
                        />
                      </div>
                    ) : null}
                    {DataSelected.status_description === "CREADO" &&
                    UserPermmission.view_employeedepartures?.codigo ===
                      "view_employeedepartures" &&
                    UserPermmission.aprove_departures?.codigo ===
                      "aprove_departures" ? (
                      <div className='col-12 d-flex justify-content-end'>
                        <Button
                          text='Rechazar Salida'
                          onClick={() => {setModal(true); setIdReasonDecline(0);}}
                          theme='danger'
                          type='button'
                        />
                        <p style={{ opacity: 0 }}>__</p>
                        <Button
                          text='Confirmar Salida'
                          onClick={handleApprove}
                          theme='success'
                          type='button'
                        />
                      </div>
                    ) : null}
                    {DataSelected.departure_type_description ===
                      "PROGRAMADA" && (
                      // UserPermmission.absence_days &&
                      <Button
                        text='Actualizar días de ausencia'
                        onClick={handleUpdateAbsenceDays}
                        theme='primary'
                        type='button'
                      />
                    )}
                    <Modal className="modal-main" show={showUpdateAbsenceModal} style={{width: "50%"}}>
                      <div className='modal-content'>
                        <form onSubmit={handleConfirmUpdateAbsenceDays}>
                          {" "}
                          {/* Use onSubmit to handle form submission */}
                          <div className='modal-header'>
                            <h5 className='modal-title'>
                              Actualizar Días de Ausencia
                            </h5>
                          </div>
                          <div className='modal-body'>
                            <div className='form-group'>
                              Días de ausencia actuales: {" "}
                              {DataSelected.absence_days}
                              <Input
                                id='absence_days'
                                name='absence_days'
                                type='number'
                                label='Nuevos días de ausencia'
                                value={newAbsenceDays} // Bind the input value to the state
                                onChange={(e) =>
                                  setNewAbsenceDays(e.target.value)
                                } // Handle input change
                              />
                            </div>
                          </div>
                          <div className="card-footer text-center">
                            <button type="button" className="btn btn-danger button_div" onClick={handleCancelUpdateAbsenceDays}>
                              Cancelar
                            </button>
                            <button type="submit" className="btn btn-success button_div">
                              Guardar
                            </button>
                            
                          </div>
                        </form>
                      </div>
                    </Modal>
                    <Modal className="modal-main" show={modal} style={{width: "30%"}}>
                      <form onSubmit={handleReject}>
                          {isLoading && <LoadingSpinner/>}
                          <div className="card-body">
                              <h5 className="titulo_modal type_text mb-2">Detalles</h5>
                              <div className="form-group">
                                <label>Motivo</label>
                                <SelectInput
                                  id='idReasonDecline'
                                  name='idReasonDecline'
                                  options={reasonsDeclide.map((v) => ({
                                    name: v.descripcion,
                                    id: v.id,
                                }))}
                                  onChange={(e) => setIdReasonDecline(parseInt(e.target.value))}
                                />
                                {/* <TextArea
                                    id="observacion"
                                    name="observacion"
                                    label="Observación"
                                    placeholder=""
                                    onChange={(e) => {setObservation(e.target.value)}}
                                    rows={4}
                                /> */}
                              </div>
                          </div>
                          <div className="card-footer text-center">
                              <button className={`btn btn-success mb-1 button_div`}>Guardar</button>
                              <button type="button" onClick={() => {setModal(false);}} className={`btn btn-outline-danger button_div`}>Cancelar</button>
                          </div>
                      </form>
                  </Modal>
                  </Card>
                </div>
              </div>
            </div>
            {/* email details  end*/}
            <div className='row px-2 mb-4'>
              <div className='col-12 px-0' />
            </div>
          </div>
          {isModalVisible && (
            <DeparteeModal
              show={isModalVisible}
              handleClose={() => setIsModalVisible(false)}
              employee={DataSelected}
              // other modal props...
            >
              {/* Modal content goes here */}
            </DeparteeModal>
          )}
        </div>
      </div>
    </div>
  );
};

export default DepartureDetailsModal;
