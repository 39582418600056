import {
  URLAPI,
  VISITS_PATH,
  VISITS_CREATE_PATH,
  VISITS_UPDATE_PATH,
  SEARCH_VISITANT_PATH,
  VISITS_REPORT_PATH,
  VISITS_REPORT_CHECK_PATH,
  VISITS_DETAILS_PATH,
  VISITS_REQUIRED_EXIT_PATH,
  VISITS_RESPONSE_EXIT_PATH,
  VISIT_MONITORING_REPORT_PATH,
  VISIT_MONITORING_REPORT_CHECK_PATH
} from "../config";
import { LOGOUT } from "./users";
import axios from "axios";

const init = {
  _get_all_visits: {
    data: [],
    status: 0,
    message: "",
  },
  _create_visit: {
    data: {},
    status: 0,
    message: "",
  },
  _update_visit: {
    data: {},
    status: 0,
    message: "",
  },
  _get_all_visitant_search: {
    data: [],
    status: 0,
    message: "",
  },
  _get_notification: {
    data: [],
  },
  _get_report: {
    data: {},
    status: 0,
    message: "",
  },
  _get_report_monitoring: {
    data: {},
    status: 0,
    message: "",
  },
};

const GET_ALL_VISITS = "GET_ALL_VISITS";
export const CREATE_VISIT = "CREATE_VISIT";
export const UPDATE_VISIT = "UPDATE_VISIT";
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_REPORT = "GET_REPORT";
export const GET_REPORT_MONITORING = "GET_REPORT_MONITORING";
export const GET_VISITS_DETAILS = "GET_VISITS_DETAILS";

export const visits_module = (state = init, action) => {
  switch (action.type) {
    case GET_ALL_VISITS:
      return {
        ...state,
        _get_all_visits: action.payload,
      };
    case GET_VISITS_DETAILS:
      return {
        ...state,
        _get_visits_details: action.payload,
      };
    case CREATE_VISIT:
      return {
        ...state,
        _create_visit: action.payload,
      };
    case UPDATE_VISIT:
      return {
        ...state,
        _update_visit: action.payload,
      };
    case GET_NOTIFICATION:
      return {
        ...state,
        _get_notification: action.payload,
      };

    case GET_REPORT:
      return {
        ...state,
        _get_report: action.payload,
      };
    case GET_REPORT_MONITORING:
      return {
        ...state,
        _get_report_monitoring: action.payload,
      };
    case LOGOUT:
      return init;
    default:
      return state;
  }
};

/**
 * @function getAllVisits
 * @description Obtiene las visitas haciendo una peticion a la API
 * @param {string} token - token de autenticacion
 * @async @return dispatch - dispatch de la accion
 */
export const getAllVisits = (token, data, page) => async (dispatch) => {
  try {
    const response = await axios.get(`${URLAPI}${VISITS_PATH}?lista_establecimiento=${data}&page=${page}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: GET_ALL_VISITS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: GET_ALL_VISITS,
        payload: {
          data: [],
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: GET_ALL_VISITS,
        payload: {
          data: [],
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const getVisitsDetails = (token, id) => async (dispatch) => {
  try{
    const response=await axios.get( `${URLAPI}${VISITS_DETAILS_PATH}${id}/`,
    {headers:{Authorization:`Token ${token}`}})
    dispatch({
      type: GET_VISITS_DETAILS,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
      }catch(err){console.log(`ERROR:${err}`);throw err;}
}

export const createVisit = (token, data) => async (dispatch) => {
  try {
    const response = await axios.post(`${URLAPI}${VISITS_CREATE_PATH}`, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    dispatch({
      type: CREATE_VISIT,
      payload: {
        data: response.data,
        status: response.status,
        message: "",
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: CREATE_VISIT,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: CREATE_VISIT,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

//update visit
export const updateVisit = (token, id, data) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${VISITS_UPDATE_PATH}${id}/`,
      data,
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_VISIT,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: UPDATE_VISIT,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_VISIT,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};
//update visit
export const visitRequiredExit = (token, id) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${VISITS_REQUIRED_EXIT_PATH}${id}/`,
      {},
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_VISIT,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: UPDATE_VISIT,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_VISIT,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const visitResponseExit = (token, id) => async (dispatch) => {
  try {
    const response = await axios.patch(
      `${URLAPI}${VISITS_RESPONSE_EXIT_PATH}${id}/`,
      {},
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
    dispatch({
      type: UPDATE_VISIT,
      payload: {
        data: response.data,
        status: response.status,
        message: response.data.mensaje,
      },
    });
  } catch (error) {
    if (error.response != null) {
      dispatch({
        type: UPDATE_VISIT,
        payload: {
          data: {},
          status: error.response.status,
          message: error.response.data.mensaje,
        },
      });
    } else {
      dispatch({
        type: UPDATE_VISIT,
        payload: {
          data: {},
          status: 500,
          message: "Error en el servidor",
        },
      });
    }
  }
};

export const get_report = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(URLAPI + VISITS_REPORT_PATH, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    dispatch({
      type: GET_REPORT,
      payload: {
        data: res.data,
        status: res.status,
        message: "",
      },
    });
  } catch (e) {
    dispatch({
      type: GET_REPORT,
      payload: {
        data: {},
        status: 500,
        message: "Ocurrio un error al generar el reporte",
      },
    });
  }
};

export const get_report_monitoring = (data, token) => async (dispatch) => {
  try {
    const res = await axios.post(URLAPI + VISIT_MONITORING_REPORT_PATH, data, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });

    dispatch({
      type: GET_REPORT_MONITORING,
      payload: {
        data: res.data,
        status: res.status,
        message: "",
      },
    });
  } catch (e) {
    dispatch({
      type: GET_REPORT_MONITORING,
      payload: {
        data: {},
        status: 500,
        message: "Ocurrio un error al generar el reporte",
      },
    });
  }
};

export const save_notification = (data) => async (dispatch) => {
  dispatch({
    type: GET_NOTIFICATION,
    payload: {
      data: data,
    },
  });
};

//clear visit
export const clearVisit = (type) => (dispatch) => {
  dispatch({
    type: type,
    payload: {
      data: {},
      status: 0,
      message: "",
    },
  });
};

export const searchVisitant = async (token, paramsUrl) => {
  return await axios.get(`${URLAPI}${SEARCH_VISITANT_PATH}${paramsUrl}`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const check_export_status = async (token, task_id) => {
  return await axios.get(`${URLAPI}${VISITS_REPORT_CHECK_PATH}${task_id}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};

export const check_export_monitoring_status = async (token, task_id) => {
  return await axios.get(`${URLAPI}${VISIT_MONITORING_REPORT_CHECK_PATH}${task_id}/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
};
