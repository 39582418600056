import React, {useEffect, useState} from "react";
import Button from "../../../components/button";
import Breadcrumbs from "../../../components/breadcrumbs";
import Input from "../../../components/input";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from "react-router-dom";
import {URLAPI} from "../../../config/index";
import {
    updateSupplier,
    clear_suppliers,
    UPDATE_SUPPLIER,
    getAllSuppliers
} from "../../../redux/supplier";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from "../../../components/loading-spinner";

const UpdateSupplier = () => {
    let history = useHistory();
    /* Getting the id from the url. */
    const {id} = useParams();

    const dispatch = useDispatch();
    /* Getting the token from the redux store. */
    const {token} = useSelector((state) => state.users_module.login.data) || null;

    /* Getting the data from the redux store. */
    const _get_all_suppliers = useSelector(
        (state) => state.supplier_module._get_all_suppliers
    );

    const _update_supplier = useSelector(
        (state) => state.supplier_module._update_supplier
    );
    /* A state variable that is used to store the data of the form. */
    const [form, setForm] = useState({
        ruc: "",
        nombre: "",
        imagen: "",
    });

    const [isLoading, setIsLoading] = useState(false);

    const [imagePreview, setImagePreview] = useState(null);

    /* A hook that is used to execute a function when the component is rendered. */
    useEffect(() => {
        if (_get_all_suppliers.data) {
            // eslint-disable-next-line
            _get_all_suppliers.data.datos.map((item) => {
                if (item.id === parseInt(id)) {
                    setForm(item);
                }
            });
        }
    }, [_get_all_suppliers, id]);

    useEffect(() => {
        if (_update_supplier.status === 200) {
            toast.success(_update_supplier.message)
            dispatch(clear_suppliers(UPDATE_SUPPLIER));
            dispatch(getAllSuppliers(token));
            setIsLoading(false);
            history.push("/proveedores");
        } else if (_update_supplier.status !== 0) {
            dispatch(clear_suppliers(UPDATE_SUPPLIER));
            toast.error(_update_supplier.message);
            setIsLoading(false);
        }
    }, [_update_supplier, dispatch, history, token]);

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        let url = URL.createObjectURL(file);
        setImagePreview(url);
        setForm({
            ...form,
            imagen: e.target.files[0],
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        //validate data
        if (form.nombre === "") {
            toast.error("Debe ingrasar el nombre");
            setIsLoading(false);
            return;
        }

        dispatch(updateSupplier(token, id, form));
    };

    return (
        <div className="app-content content">
            <ToastContainer/>
            {isLoading && <LoadingSpinner/>}
            <div className="content-overlay"/>

            <div className="content-wrapper">
                <div className="content-header row">
                    <div className="content-header-left col-12 mb-2 mt-1">
                        <Breadcrumbs
                            title="Proveedores"
                            items={[
                                {label: "Proveedores", link: "/proveedores"},
                            ]}
                        />
                    </div>
                </div>
                <div className="content-body">
                    <div className="row">
                        <div className="col-12">
                            <form className="card" onSubmit={handleSubmit}>
                                <div className="card-header">
                                    <h4 className="card-title">Actualizar Proveedor</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="ruc"
                                                label="RUC"
                                                value={form.ruc}
                                                onChange={handleChange}
                                                readonly="readonly"
                                            />
                                        </div>
                                        <div
                                            className="
                          col-md-6"
                                        >
                                            <Input
                                                name="nombre"
                                                label="Nombre"
                                                value={form.nombre}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <Input
                                                name="imagen"
                                                label="Imagen"
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <img
                                                src={
                                                    imagePreview == null
                                                        ? `${URLAPI}${form.foto}`
                                                        : imagePreview
                                                }
                                                alt="imagen"
                                                width={60}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer d-flex justify-content-end">
                                    <Link to="/proveedores" className="btn btn-danger m-1">
                                        Cancelar
                                    </Link>
                                    <Button
                                        type="submit"
                                        text="Guardar"
                                        theme="success"
                                        classes="m-1"
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateSupplier;
